import React from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import ScheduleModel from 'models/schedule';

import Schedule from './schedule';

/**
 * 予定の一覧を表示する
 * @returns
 */
const ScheduleList = (props: { scheduleModelList?: ScheduleModel[] }) => {
  return (
    <Grid container>
      {props.scheduleModelList?.map((scheduleModel) => {
        return (
          <Grid key={scheduleModel.cropsId} xs={6} sm={3} md={3} lg={2} xl={2}>
            <Schedule scheduleModel={scheduleModel} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ScheduleList;

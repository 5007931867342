import PasswordModel from 'models/password';
import SignInModel from 'models/signIn';
import UserModel from 'models/user';
import { Result, apiAccess } from 'utils/apiUtils';

/**
 * SignInのAPI処理
 * @param signIn SignModel
 * @returns resultData
 */
export const signIn = async (signIn: SignInModel): Promise<Result<{ token: string }>> => {
  return await apiAccess<{ token: string }>('POST', 'user/signin', signIn);
};

/**
 * ログインユーザーの農家情報を取得する
 * @returns ログインユーザーのユーザー情報
 */
export const getUser = async (): Promise<Result<UserModel>> => {
  return await apiAccess<UserModel>('GET', 'user');
};

/**
 * ログインユーザーの購入希望者を取得する
 * @returns ログインユーザーの販売している購入希望者リスト
 */
export const getPurchaseUserList = async (): Promise<Result<UserModel[]>> => {
  return await apiAccess<UserModel[]>('GET', 'user/purchaseuserlist');
};

/**
 * Emailの重複情報を取得する
 * @param email Email
 * @returns true: ある、false: ない
 */
export const hasEmail = async (
  userType: string,
  email: string
): Promise<Result<{ hasEmail: boolean }>> => {
  return await apiAccess<{ hasEmail: boolean }>('POST', 'user/duplicate', {
    userType: userType,
    email: email,
  });
};

/**
 * ユーザー情報を登録する
 * @param temp 添付コード
 * @returns ユーザー情報
 */
export const insertUser = async (temp: string) => {
  return await apiAccess<{ token: string }>('POST', 'user/create', { temp: temp });
};

/**
 * ユーザー情報を更新する
 * @param userModel ユーザーモデル
 * @returns ユーザー情報
 */
export const updateUser = async (userModel: UserModel): Promise<Result<UserModel>> => {
  return await apiAccess<UserModel>('PUT', 'user', userModel);
};

/**
 * パスワードのリセット
 * @param emailModel メールモデル
 * @returns トークン
 */
export const resetPassword = async (
  passwordModel: PasswordModel
): Promise<Result<{ token: string }>> => {
  return await apiAccess<{ token: string }>('PUT', 'user/password', passwordModel);
};

import React from 'react';

import { faClock, faCarrot, faFileLines, faPersonDigging } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BottomNavigationAction,
  Box,
  Chip,
  Container,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import AvatarFileUpload from 'components/avatarFileUpload';
import BottomAppBar from 'components/bottomAppBar';
import CalendarTextField from 'components/calendarTextField';
import MenuAppBar from 'components/menuBar';
import NavigateMotion from 'components/navigateMotion';
import { WorkType, WorkTime } from 'data';
import { FaSave } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

import useCultivation from './useCultivation';

/**
 * 栽培情報ページ
 * @returns
 */
const Cultivation: React.FC = () => {
  const {
    t,
    avatar,
    control,
    errors,
    register,
    handleSubmit,
    isNew,
    workTypeChipClick,
    workTimeChipClick,
    imageChange,
    onYasaiClick,
    onSaveClick,
    onDeleteClick,
  } = useCultivation();
  return (
    <React.Fragment>
      <MenuAppBar title={t('cultivation.title')} />
      <Container maxWidth={'sm'} component="form" onSubmit={handleSubmit(onSaveClick)}>
        <NavigateMotion>
          {/** ----------
            * 写真
            ---------- */}
          <AvatarFileUpload base64Image={avatar} onChange={imageChange} />
          <Stack spacing={5}>
            {/** ----------
             * 作業日
             ---------- */}
            <CalendarTextField
              name="workingDate"
              control={control}
              label={t('cultivation.label.作業日')}
              error={'workingDate' in errors}
              helperText={errors.workingDate?.message}
            />
            {/** ----------
             * 作業区分
             ---------- */}
            <TextField
              {...register('workType')}
              error={'workType' in errors}
              helperText={errors.workType?.message}
              label={t('cultivation.label.作業区分')}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faPersonDigging} />
                  </InputAdornment>
                ),
              }}
            />
            <Box style={{ margin: '5px' }}>
              {WorkType.map((name, index) => {
                return (
                  <React.Fragment key={index}>
                    <Chip
                      sx={{ mr: 2, mb: 1 }}
                      label={name}
                      variant="outlined"
                      onClick={() => workTypeChipClick(name)}
                    />
                  </React.Fragment>
                );
              })}
            </Box>
            {/** ----------
             * 作業時間
             ---------- */}
            <TextField
              {...register('workTime')}
              error={'workTime' in errors}
              helperText={errors.workTime?.message}
              label={t('cultivation.label.作業時間')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faClock} />
                  </InputAdornment>
                ),
                endAdornment: <InputAdornment position="end">{t('unit.時間')}</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
            />
            <Box style={{ margin: '5px' }}>
              {WorkTime.map((time, index) => {
                return (
                  <React.Fragment key={index}>
                    <Chip
                      sx={{ mr: 2, mb: 1 }}
                      label={time}
                      variant="outlined"
                      onClick={() => workTimeChipClick(time)}
                    />
                  </React.Fragment>
                );
              })}
            </Box>
            {/** ----------
             * 作業内容
             ---------- */}
            <TextField
              {...register('memo')}
              error={'memo' in errors}
              helperText={errors.memo?.message}
              label={t('cultivation.label.作業内容')}
              multiline={true}
              rows={5}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faFileLines} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </NavigateMotion>
        <BottomAppBar>
          <BottomNavigationAction
            label={t('menu.やさい一覧')}
            icon={<FontAwesomeIcon icon={faCarrot} />}
            onClick={onYasaiClick}
          />
          <BottomNavigationAction
            label={t('menu.保存')}
            icon={<FaSave />}
            type="submit"
          ></BottomNavigationAction>
          {!isNew && (
            <BottomNavigationAction
              label={t('menu.削除')}
              icon={<MdDelete />}
              type="button"
              onClick={onDeleteClick}
            />
          )}
        </BottomAppBar>
      </Container>
    </React.Fragment>
  );
};

export default Cultivation;

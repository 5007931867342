import { useEffect, useState } from 'react';

import { getWantPurchaseList, savePurchase } from 'api/purchase';
import { getPurchaseSalesList } from 'api/sales';
import { getPurchaseUserList } from 'api/user';
import { Path, PurchaseType } from 'enum';
import useCommon from 'features/useCommon';
import PurchaseModel from 'models/purchase';
import SalesModel from 'models/sales';
import UserModel from 'models/user';

/**
 * PotentialBuyerListのカスタムフック
 * @returns PotentialBuyerListで使用するカスタムフック
 */
const usePotentialBuyerList = () => {
  const { t, navigate, subsequentApiProcess, enqueueSnackbar, loading } = useCommon();
  // 購入情報リスト
  const [purchaseModelList, setPurchaseModelList] = useState<PurchaseModel[]>();
  // 販売情報リスト
  const [salesModelList, setSalesModelList] = useState<SalesModel[]>();
  // 購入対象のユーザー情報リスト
  const [userModelList, setUserModelList] = useState<UserModel[]>();
  // 初期データ取得
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * 購入一覧で取得するデータを取得する
   */
  const fetchData = async () => {
    loading(true);
    await Promise.all([fetchSalesModelList(), fetchPurchaseModelList(), fetchUserModelList()]);
    loading(false);
  };

  /**
   * 購入希望が存在する、販売対象情報を取得する
   * @returns Promise
   */
  const fetchSalesModelList = async () => {
    const result = await getPurchaseSalesList();
    subsequentApiProcess(result.status, () => {
      setSalesModelList(result.data as SalesModel[]);
    });
  };

  /**
   * 購入希望者情報を取得する
   */
  const fetchUserModelList = async () => {
    const result = await getPurchaseUserList();
    subsequentApiProcess(result.status, () => {
      setUserModelList(result.data as UserModel[]);
    });
  };

  /**
   * 購入情報を取得する
   * @returns Promise
   */
  const fetchPurchaseModelList = async () => {
    const result = await getWantPurchaseList();
    subsequentApiProcess(result.status, () => {
      setPurchaseModelList(result.data as PurchaseModel[]);
    });
  };

  /**
   * 野菜一覧をクリック
   * @param e event
   */
  const onYasaiClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`yasaiClick start e: ${e}`);
    // 野菜一覧へ遷移する
    navigate(Path.cropsList);
    console.debug(`yasaiClick end`);
  };

  /**
   * 販売IDを元に購入情報リストを取得する
   * @param salesId 販売ID
   * @return 購入情報リスト
   */
  const getPurchaseModelList = (salesId: number): PurchaseModel[] | undefined => {
    return purchaseModelList?.filter((x) => x.salesId === salesId);
  };

  /**
   * ユーザーIDからユーザー情報を取得する
   * @param userId ユーザーID
   * @returns ユーザーモデル
   */
  const getUserModel = (userId: number): UserModel | undefined => {
    return userModelList?.find((x) => x.userId === userId);
  };

  /**
   * 購入ボタンクリック
   * @param purchaseId 購買キー
   */
  const onPurchaseClick = async (purchaseId: number) => {
    console.log(`onBuyClick start purchaseId: ${purchaseId}`);
    const purchaseModel = purchaseModelList?.find((x) => x.purchaseId === purchaseId);
    if (!purchaseModel) {
      return;
    }
    purchaseModel.purchaseType = PurchaseType.confirm;
    await registPurchase(purchaseModel, t(`potentialBuyerList.message.注文を確定しました。`));
  };

  /**
   * 断るボタンクリック
   * @param purchaseId 購買キー
   */
  const onDeclineClick = async (purchaseId: number) => {
    console.log(`onRejectClick start purchaseId: ${purchaseId}`);
    const purchaseModel = purchaseModelList?.find((x) => x.purchaseId === purchaseId);
    if (!purchaseModel) {
      return;
    }
    purchaseModel.purchaseType = PurchaseType.decline;
    await registPurchase(purchaseModel, t(`potentialBuyerList.message.注文をお断りしました。`));
  };

  /**
   * 購入情報登録APIを呼び出す
   * @param purchaseModel 購入モデル
   * @param successMessage 成功時のメッセージ
   */
  const registPurchase = async (purchaseModel: PurchaseModel, successMessage: string) => {
    loading(true);
    const result = await savePurchase(purchaseModel);
    // APIの後続処理
    subsequentApiProcess(result.status, async () => {
      enqueueSnackbar(successMessage);
      await fetchData();
    });
    loading(false);
  };

  return {
    t,
    salesModelList,
    getPurchaseModelList,
    getUserModel,
    onPurchaseClick,
    onDeclineClick,
    onYasaiClick,
  };
};

export default usePotentialBuyerList;

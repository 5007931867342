import { useSelector as rawUseSelector, TypedUseSelectorHook } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';
import loginUserReducer from 'slices/loginUserSlice';
import tokenReducer from 'slices/tokenSlice';

/**
 * reduxのstoreを定義
 */
export const store = configureStore({
  reducer: {
    loginUser: loginUserReducer,
    token: tokenReducer,
  },
});

export type AppDispath = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;

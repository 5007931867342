import React from 'react';
import { Link } from 'react-router-dom';

import { faYenSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Edit } from '@mui/icons-material';
import {
  Avatar,
  Badge,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { Path } from 'enum';
import SalesModel from 'models/sales';
import { FaBox } from 'react-icons/fa';
import { RiFootprintFill } from 'react-icons/ri';
import CommonUtils from 'utils/commonUtils';

/**
 * 販売ページ
 * @returns
 */
const SalesItem = (props: SalesModel) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: 'orange',
            width: 40,
            height: 40,
            my: 1,
            mr: 1,
          }}
          alt={`${props.salesName.slice(0, 1)}`}
          src={CommonUtils.base64ImageSrc(props.salesImage)}
        />
      </ListItemAvatar>
      {/* TODO: 足跡機能の未実装 */}
      <Badge
        sx={{ mr: 2 }}
        style={{ display: 'none' }}
        badgeContent={props.viewerCount}
        color="primary"
      >
        <RiFootprintFill color={'#90623c'} size={'25'} />
      </Badge>
      <ListItemText
        primary={CommonUtils.dateFormat(props.salesDate)}
        primaryTypographyProps={{ variant: 'h6' }}
        secondary={
          <>
            <Typography
              sx={{ mr: 1, display: 'inline' }}
              component="span"
              variant="h4"
              color="text.primary"
            >
              {props.salesName}
            </Typography>
            <br />
            <Typography
              sx={{ mr: 1, display: 'inline' }}
              component="span"
              variant="h6"
              color="text.primary"
            >
              <FaBox style={{ marginRight: 2, marginTop: 5 }} color="#b47142" />
              {`${props.stock.toLocaleString()}`}
            </Typography>
            <br />
            <Typography
              sx={{ mr: 1, display: 'inline' }}
              component="span"
              variant="h6"
              color="text.primary"
            >
              {`${CommonUtils.conbine(
                '1',
                props.unit.toLocaleString(),
                props.quantity.toLocaleString(),
                '個入'
              )}`}
              <FontAwesomeIcon
                style={{ marginLeft: 5, marginRight: 2 }}
                icon={faYenSign}
                color={`#F79101`}
              />
              {`${props.amount.toLocaleString()}`}
            </Typography>
            <br />
            <Typography
              sx={{ whiteSpace: 'pre-line', display: 'inline' }}
              component="span"
              variant="h6"
              color="text.primary"
            >
              {`${props.memo}`}
            </Typography>
          </>
        }
      />
      <IconButton sx={{ width: 30, height: 30 }}>
        <Link to={Path.sales} state={{ salesId: props.salesId, cropsId: props.cropsId }}>
          <Edit />
        </Link>
      </IconButton>
    </ListItem>
  );
};

export default SalesItem;

import { getUser } from 'api/user';
import { format, isBefore, isSameDay } from 'date-fns';
import { StatusCode, UserType } from 'enum';
import UserModel from 'models/user';

/**
 * 共通メソッド
 */
export default class CommonUtils {
  /**
   * 特定のミリ秒スリープする
   * @param milsec ミリ秒
   * @returns void
   */
  static sleep = (milsec: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, milsec));
  };

  /**
   * Chrome判定
   * return true: chrome, false: chrome以外
   */
  static isChrome = (): boolean => {
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf('chrome')) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * 画像をbase64形式に変換する
   * @param file ファイルデータ
   * @returns base64
   */
  static imageToBase64 = (file: File): Promise<string> => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onload = (_) => {
        // 値が読み込めない場合
        if (!reader.result) {
          resolve('');
          return;
        }
        if (!reader.result) {
          resolve('');
          return;
        }
        // base64変換
        const offset = (reader.result as string).indexOf(',') + 1;
        resolve((reader.result as string).slice(offset));
        return;
      };
    });
  };

  /**
   * base64をイメージタグで表示できる形式にする
   * @param base64 base64文字列
   * @returns
   */
  static base64ImageSrc = (base64?: string) => {
    if (base64 !== undefined && base64 !== null) {
      return `data:image/png+jpg;base64,${base64}`;
    }
    return undefined;
  };

  /**
   * 日付をフォーマットする
   * @param date 日付
   */
  static dateFormat = (date?: Date): string => {
    if (!date) {
      return '';
    }
    if (typeof date === 'string') {
      date = new Date(date);
    }
    return format(date, `yyyy/MM/dd`);
  };

  /**
   * 当日の日付を取得する
   * @returns 当日日付を取得する
   */
  static dateNow = (): Date => {
    return new Date(format(new Date(), 'yyyy/MM/dd'));
  };

  /**
   * 過去日か判定する
   * @param date 対象日付
   * @returns
   */
  static isBeforeNow = (date: Date): boolean => {
    const now = this.dateNow();
    return isBefore(date, now);
  };

  /**
   * 日付が同一日か判定する
   * @param date1 対象日付１
   * @param date2 対象日付２
   * @returns true: 同じ、false: 違う
   */
  static isSameDay = (date1: Date, date2: Date): boolean => {
    return isSameDay(date1, date2);
  };

  /**
   * NullかUndefinedを判定する
   * @param target null or undefinedを調べる対象
   * @returns true: null or undefined, false: それ以外
   */
  static isNullOrUndefined = (target: unknown) => {
    if (target == null) {
      return true;
    }
    return false;
  };

  /**
   * 文字列を結合する
   * @param params 結合対象の文字列
   * @returns 結合された文字列
   */
  static conbine = (...params: (string | undefined)[]) => {
    let result = '';
    for (const param of params) {
      if (!this.isNullOrUndefined(param)) {
        result += param;
      }
    }
    return result;
  };

  /**
   * Deepコピーする
   * @param target 対象オブジェクト
   * @returns コピーしたインスタンス
   */
  static deepCopy = <T>(target: T) => {
    return JSON.parse(JSON.stringify(target)) as T;
  };

  /**
   * 購入者でログイン状態かを取得する
   * @returns true: ログイン、false: ログインしてない
   */
  static isLoginBuyer = async (): Promise<boolean> => {
    const result = await getUser();
    if (result.status !== StatusCode.Success) {
      return false;
    }
    const user = result.data as UserModel;
    if (user.userType === UserType.buyer) {
      return true;
    }
    return false;
  };
}

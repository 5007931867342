import React from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat, PatternFormat } from 'react-number-format';

import { faCarrot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LockOutlined, Search } from '@mui/icons-material';
import {
  BottomNavigationAction,
  Button,
  Container,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import AvatarFileUpload from 'components/avatarFileUpload';
import BottomAppBar from 'components/bottomAppBar';
import MenuAppBar from 'components/menuBar';
import NavigateMotion from 'components/navigateMotion';
import ShopMenuBar from 'components/shopMenuBar';
import { UserType } from 'enum';
import { BsPinMapFill, BsFillTelephoneFill } from 'react-icons/bs';
import { FaBuilding, FaKey, FaSave, FaUser } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';

import useUser from './useUser';

/**
 * ユーザー情報ページ
 * @returns
 */
const User: React.FC<{ isNew: boolean; userType: UserType }> = (props) => {
  const {
    t,
    avatar,
    control,
    errors,
    register,
    handleSubmit,
    getTitle,
    imageChange,
    onYasaiClick,
    onSaveClick,
    onSigninClick,
    onZipClick,
  } = useUser(props);

  return (
    <React.Fragment>
      {props.userType === UserType.farmer && (
        <MenuAppBar isVisibleLogout={!props.isNew} title={getTitle()} />
      )}
      {props.userType === UserType.buyer && <ShopMenuBar />}
      <Container maxWidth={'sm'} component="form" onSubmit={handleSubmit(onSaveClick)}>
        <NavigateMotion>
          {/** ----------
            * 写真
            ---------- */}
          <AvatarFileUpload base64Image={avatar} onChange={imageChange} />

          <Stack spacing={3}>
            {/** ----------
             * 名前
             ---------- */}
            <TextField
              {...register('userName')}
              error={'name' in errors}
              helperText={errors.userName?.message}
              label={t('user.label.名前')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaUser />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * メールアドレス
             ---------- */}
            <TextField
              {...register('email')}
              error={'email' in errors}
              helperText={errors.email?.message}
              label={t('user.label.メールアドレス')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <GrMail />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * パスワード
             ---------- */}
            <TextField
              {...register('password')}
              error={'password' in errors}
              helperText={errors.password?.message}
              label={t('user.label.パスワード')}
              type="password"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaKey />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * パスワードの確認
             ---------- */}
            <TextField
              {...register('comfirmPassword')}
              error={'comfirmPassword' in errors}
              helperText={errors.comfirmPassword?.message}
              label={t('user.label.パスワードの確認')}
              type="password"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaKey />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * 郵便番号
             ---------- */}
            <Grid container>
              <Grid xs={6}>
                <Controller
                  name="postalCode"
                  control={control}
                  render={({ field: { name, value, onBlur, onChange } }) => (
                    <PatternFormat
                      type="tel"
                      format="###-####"
                      mask={'_'}
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      onValueChange={(v) => onChange(v.value)}
                      customInput={TextField}
                      error={'postalCode' in errors}
                      helperText={errors.postalCode?.message}
                      label={t('user.label.郵便番号')}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">〒</InputAdornment>,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid xs={6}>
                <Button
                  startIcon={<Search />}
                  sx={{ ml: 1, mt: 1 }}
                  variant="contained"
                  onClick={onZipClick}
                >
                  {t('user.label.住所を取得する')}
                </Button>
              </Grid>
            </Grid>

            {/** ----------
             * 都道府県
             ---------- */}
            <TextField
              {...register('prefecture')}
              error={'prefecture' in errors}
              helperText={errors.prefecture?.message}
              label={t('user.label.都道府県')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsPinMapFill />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * 市区町村
             ---------- */}
            <TextField
              {...register('municipality')}
              error={'municipality' in errors}
              helperText={errors.municipality?.message}
              label={t('user.label.市区町村')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsPinMapFill />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * 丁目番地
             ---------- */}
            <TextField
              {...register('streetNumber')}
              error={'streetNumber' in errors}
              helperText={errors.streetNumber?.message}
              label={t('user.label.丁目番地号')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BsPinMapFill />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * 建物
             ---------- */}
            <TextField
              {...register('building')}
              error={'building' in errors}
              helperText={errors.building?.message}
              label={t('user.label.建物')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaBuilding />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * 電話番号
             ---------- */}
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <NumericFormat
                  type="tel"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onValueChange={(v) => onChange(v.value)}
                  customInput={TextField}
                  error={'phoneNumber' in errors}
                  helperText={errors.phoneNumber?.message}
                  label={t('user.label.電話番号')}
                  InputLabelProps={{ shrink: true }}
                  allowLeadingZeros={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BsFillTelephoneFill />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Stack>
        </NavigateMotion>
        <BottomAppBar>
          {/* ログイン時の農家メニュー */}
          {!props.isNew && props.userType === UserType.farmer && (
            <BottomNavigationAction
              label={t('menu.やさい一覧')}
              icon={<FontAwesomeIcon icon={faCarrot} />}
              onClick={onYasaiClick}
            />
          )}
          {/* ユーザー作成 */}
          {props.isNew && props.userType === UserType.farmer && (
            <BottomNavigationAction
              label={t('menu.ログインへ')}
              icon={<LockOutlined />}
              onClick={onSigninClick}
            />
          )}
          <BottomNavigationAction
            label={t('menu.保存')}
            icon={<FaSave />}
            type="submit"
          ></BottomNavigationAction>
        </BottomAppBar>
      </Container>
    </React.Fragment>
  );
};

export default User;

import React from 'react';
import { Link } from 'react-router-dom';

import { faLeaf, faPepperHot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Edit, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import { Path } from 'enum';
import CultivationItem from 'features/cropsList/components/cultivationItem';
import SalesItem from 'features/cropsList/components/salesItem';
import CropsModel from 'models/crops';
import CultivationModel from 'models/cultivation';
import SalesModel from 'models/sales';
import CommonUtils from 'utils/commonUtils';

import useCropsItem from './useCropsItem';

/**
 * 農作物ページ
 * @returns
 */
const CropsItem = (props: {
  cropsModel: CropsModel;
  cultivationModelList: CultivationModel[];
  salesModelList: SalesModel[];
}) => {
  // カスタムフック
  const { t, getSalesString } = useCropsItem(props);

  return (
    <Paper>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              sx={{
                bgcolor: 'transparent',
                width: 60,
                height: 60,
                my: 2,
                mr: 1,
              }}
              alt="や"
              src={CommonUtils.base64ImageSrc(props.cropsModel.cropsImage)}
            />
          </ListItemAvatar>
          <ListItemText
            primary={props.cropsModel.cropsName}
            primaryTypographyProps={{ variant: 'h4' }}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="h6"
                  color="text.primary"
                >
                  {`${getSalesString}`}
                </Typography>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="h5"
                  color="text.primary"
                >
                  {`${CommonUtils.dateFormat(props.cropsModel.salesScheduleDate)}`}
                </Typography>
              </React.Fragment>
            }
          />
          <IconButton sx={{ width: 30, height: 30 }}>
            <Link to={Path.crops} state={{ cropsId: props.cropsModel.cropsId }}>
              <Edit />
            </Link>
          </IconButton>
        </ListItem>
      </List>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <IconButton sx={{ width: 30, height: 30 }}>
            <Link
              to={Path.cultivation}
              state={{ cultivationId: 0, cropsId: props.cropsModel.cropsId }}
            >
              <FontAwesomeIcon icon={faLeaf} color={`green`} />
            </Link>
          </IconButton>
          <Typography sx={{ ml: 1 }} variant="h4">
            {t('cropsList.label.栽培履歴')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pr: 0 }}>
          {/* 栽培履歴を表示する */}
          <List>
            {props.cultivationModelList?.length > 0 &&
              props.cultivationModelList?.map((cultivationModel) => {
                return (
                  <React.Fragment key={cultivationModel.cultivationId}>
                    <Divider variant="inset" component="li" />
                    <CultivationItem key={cultivationModel.cultivationId} {...cultivationModel} />
                  </React.Fragment>
                );
              })}
            {props.cultivationModelList.length === 0 && (
              <ListItem>
                <ListItemText
                  primary={t('cropsList.message.栽培履歴は登録されていません。')}
                  primaryTypographyProps={{ variant: 'h5' }}
                ></ListItemText>
              </ListItem>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <IconButton sx={{ width: 30, height: 30 }}>
            <Link to={Path.sales} state={{ salesId: 0, cropsId: props.cropsModel.cropsId }}>
              <FontAwesomeIcon icon={faPepperHot} color={`red`} />
            </Link>
          </IconButton>
          <Typography sx={{ ml: 1 }} variant="h4">
            {t('cropsList.label.販売一覧')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pr: 0 }}>
          {/* 販売一覧を表示する */}
          <List>
            {props.salesModelList?.length > 0 &&
              props.salesModelList?.map((salesModel) => {
                return (
                  <React.Fragment key={salesModel.salesId}>
                    <Divider variant="inset" component="li" />
                    <SalesItem key={salesModel.salesId} {...salesModel} />
                  </React.Fragment>
                );
              })}
            {props.salesModelList?.length === 0 && (
              <ListItem>
                <ListItemText
                  primary={t('cropsList.message.商品は登録されていません。')}
                  primaryTypographyProps={{ variant: 'h5' }}
                ></ListItemText>
              </ListItem>
            )}
          </List>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default CropsItem;

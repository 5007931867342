import React, { useState, createContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import 'swiper/swiper-bundle.min.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import './i18n/configs';
import { getNationalHolidayList } from 'api/nationalHoliday';
import Loading from 'components/loading';
import MessageDialog from 'components/messageDialog';
import { initializeMessageDialogConfig, MessageDialogConfig } from 'components/useMessageDialog';
import { Path, StatusCode, UserType } from 'enum';
import Crops from 'features/crops';
import CropsList from 'features/cropsList';
import Cultivation from 'features/cultivation';
import Email from 'features/email';
import Password from 'features/password';
import PotentialBuyerList from 'features/potentialBuyerList';
import PurchaseHistory from 'features/purchaseHistory';
import Sales from 'features/sales';
import Shop from 'features/shop';
import ShopItem from 'features/shopItem';
import SignIn from 'features/signIn';
import TemporaryRegistration from 'features/temporayRegistration';
import Uesr from 'features/user';
import { AnimatePresence } from 'framer-motion';
import NationalHolidayModel from 'models/nationalHoliday';
import { SnackbarProvider } from 'notistack';

/**
 * Appコンテキストの型
 */
type AppContextType = {
  setLoadingOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDialogConfig: React.Dispatch<React.SetStateAction<MessageDialogConfig>>;
  nationalHolidayModelList: NationalHolidayModel[];
};

// AppContext作成
export const AppContext = createContext<AppContextType | undefined>(undefined);

const App = () => {
  // context
  const [loadingOpen, setLoadingOpen] = useState<boolean>(false);
  const [dialogConfig, setDialogConfig] = useState<MessageDialogConfig>(
    initializeMessageDialogConfig()
  );
  const [nationalHolidayModelList, setNationalHolidayModelList] = useState<NationalHolidayModel[]>(
    new Array<NationalHolidayModel>()
  );

  useEffect(() => {
    console.log('App useEffect start ');
    // Promise.all([fetchNationalHolidayModel]);
    fetchNationalHolidayModel();
  }, []);

  /**
   * 祝日データの取得
   */
  const fetchNationalHolidayModel = async () => {
    const result = await getNationalHolidayList();
    if (result.status === StatusCode.Success) {
      setNationalHolidayModelList(result.data as NationalHolidayModel[]);
    }
  };

  const appContextValue = {
    setLoadingOpen: setLoadingOpen,
    setDialogConfig: setDialogConfig,
    nationalHolidayModelList: nationalHolidayModelList,
  };

  // const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = createTheme({
    typography: {
      h1: { fontSize: 48 },
      h2: { fontSize: 38 },
      h3: { fontSize: 28 },
      h4: { fontSize: 20 },
      h5: { fontSize: 18 },
      h6: { fontSize: 14 },
      subtitle1: { fontSize: 18 },
      body1: { fontSize: 18 },
      body2: { fontSize: 16 },
    },
    components: {
      MuiInputLabel: {
        styleOverrides: {
          // formControl: {
          //   shrink: true,
          //   margin: 'dense',
          //   // 移動をクリック時に動かないように固定
          //   position: 'static',
          //   transform: 'none',
          //   transition: 'none',
          //   // クリックを可能に
          //   pointerEvents: 'auto',
          //   cursor: 'pointer',
          //   // 幅いっぱいを解除
          //   display: 'inline',
          //   alignSelf: 'start',
          //   // タイポグラフィを指定
          //   fontWeight: 'bold',
          //   fontSize: '1.00rem',
          // },
        },
      },
    },
    palette: {
      // mode: isDarkMode ? 'dark' : 'light',
      mode: 'light',
    },
  });

  return (
    <AnimatePresence onExitComplete={() => window.scrollTo(0, 0)}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
          <AppContext.Provider value={appContextValue}>
            <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
              <Routes>
                {/* 本登録 */}
                <Route path={Path.userRegistration} element={<TemporaryRegistration />} />
                {/* パスワードの変更メール送信（農家） */}
                <Route path={Path.farmerEmail} element={<Email userType={UserType.farmer} />} />
                {/* パスワードの変更メール送信（購入者） */}
                <Route path={Path.buyerEmail} element={<Email userType={UserType.buyer} />} />
                {/* パスワードの変更 */}
                <Route path={Path.password} element={<Password />} />
                {/** --------------------
                 * 農家用
                 * -------------------- */}
                {/* 作物 */}
                <Route path={Path.crops} element={<Crops />} />
                {/* 作物一覧 */}
                <Route path={Path.cropsList} element={<CropsList />} />
                {/* 栽培登録 */}
                <Route path={Path.cultivation} element={<Cultivation />} />
                {/* 購入希望一覧 */}
                <Route path={Path.potentialBuyerList} element={<PotentialBuyerList />} />
                {/* 販売登録 */}
                <Route path={Path.sales} element={<Sales />} />
                {/* 農家 ログイン */}
                <Route path={Path.farmerSignin} element={<SignIn userType={UserType.farmer} />} />
                {/* 農家更新 */}
                <Route
                  path={Path.farmerUpdate}
                  element={<Uesr isNew={false} userType={UserType.farmer} />}
                />
                {/* 農家登録 */}
                <Route
                  path={Path.farmerCreate}
                  element={<Uesr isNew={true} userType={UserType.farmer} />}
                />
                {/** --------------------
                 * 購入用
                 * -------------------- */}
                {/* 農家更新 */}
                <Route
                  path={Path.buyerUpdate}
                  element={<Uesr isNew={false} userType={UserType.buyer} />}
                />
                {/* 農家登録 */}
                <Route
                  path={Path.buyerCreate}
                  element={<Uesr isNew={true} userType={UserType.buyer} />}
                />
                {/* 販売一覧 */}
                <Route path={Path.shop} element={<Shop />} />
                {/* 販売商品 */}
                <Route
                  path={`${Path.shopItem}/:paramUserId/:paramSalesId`}
                  element={<ShopItem />}
                />
                {/* 販売 ログイン */}
                <Route path={Path.buyerSignin} element={<SignIn userType={UserType.buyer} />} />
                {/* 購入履歴 */}
                <Route path={Path.purchaseHistory} element={<PurchaseHistory />} />
              </Routes>
            </BrowserRouter>
            <Loading open={loadingOpen} />
            <MessageDialog messageDialogConfig={dialogConfig}></MessageDialog>
          </AppContext.Provider>
        </SnackbarProvider>
      </ThemeProvider>
    </AnimatePresence>
  );
};

export default App;

import PurchaseModel from 'models/purchase';
import { Result, apiAccess } from 'utils/apiUtils';

/**
 * ログインユーザーの購入情報を取得する
 * @returns PurchaseModel[]
 */
export const getPurchaseList = async (): Promise<Result<PurchaseModel[]>> => {
  return await apiAccess<PurchaseModel[]>('GET', `purchase/list`);
};

/**
 * 販売IDから購入情報を取得する
 * @param salesId 販売ID
 * @returns PurchaseModel[]
 */
export const getPurchaseListBySalesId = async (
  salesId: number
): Promise<Result<PurchaseModel[]>> => {
  return await apiAccess<PurchaseModel[]>('GET', `purchase/list/${salesId}`);
};

/**
 * 販売中の購入希望のリストを取得する
 * @returns PurchaseModel[]
 */
export const getWantPurchaseList = async (): Promise<Result<PurchaseModel[]>> => {
  return await apiAccess<PurchaseModel[]>('GET', `purchase/wantpurchaselist`);
};

/**
 * 購入IDから購入情報を取得する
 * @param purchaseId 購入ID
 * @returns PurchaseModel
 */
export const getPurchase = async (purchaseId: number): Promise<Result<PurchaseModel>> => {
  return await apiAccess<PurchaseModel>('GET', `purchase/${purchaseId}`);
};

/**
 * 購入希望で未処理の件数を取得する
 * @returns count: number
 */
export const getUntreatedCount = async (): Promise<Result<{ count: number }>> => {
  return await apiAccess<{ count: number }>('GET', `purchase/untreatedcount`);
};

/**
 * 保存APIを起動する
 * @param model 購入モデル
 * @returns PurchaseModel
 */
export const savePurchase = async (model: PurchaseModel): Promise<Result<PurchaseModel>> => {
  if (model.purchaseId === 0) {
    return await apiAccess('POST', 'purchase', model);
  } else {
    return await apiAccess('PUT', 'purchase', model);
  }
};

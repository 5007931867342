import NationalHolidayModel from 'models/nationalHoliday';
import { Result, apiAccess } from 'utils/apiUtils';

/**
 * 祝日リストを取得する
 * @returns 祝日リスト
 */
export const getNationalHolidayList = async (): Promise<Result<NationalHolidayModel[]>> => {
  return await apiAccess('GET', 'nationalholiday/list');
};

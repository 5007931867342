import React from 'react';

import { Login } from '@mui/icons-material';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ItemList from 'components/itemList';
import ScheduleList from 'components/scheduleList';
import SearchTextField from 'components/searchTextField';
import ShopMenuBar from 'components/shopMenuBar';
import Slide from 'components/slide';

import useShop from './useShop';

/**
 * Topページ
 * @returns
 */
const Shop = () => {
  const {
    t,
    imageList,
    shopModelList,
    scheduleModelList,
    onCardClick,
    onSearchClick,
    onFarmerSigninClick,
  } = useShop();
  return (
    <React.Fragment>
      <ShopMenuBar>
        <SearchTextField
          placeholder={t(`shop.message.商品名で検索する`)}
          onSearchClick={onSearchClick}
        ></SearchTextField>
      </ShopMenuBar>
      <Slide imageList={imageList} />
      <Grid container justifyContent={'end'} xs={12}>
        <Grid sx={{ m: 1 }}>
          <Button
            color={'success'}
            onClick={onFarmerSigninClick}
            variant="contained"
            endIcon={<Login></Login>}
          >
            {t('shop.label.農家の人はコチラ')}
          </Button>
        </Grid>
      </Grid>
      <Container>
        <Box sx={{ mt: 1, mb: 1, borderBottom: 1, borderLeft: 6, pl: 2 }}>
          <Typography variant="h4">{t(`shop.label.販売中`)}</Typography>
        </Box>
        {shopModelList !== undefined && shopModelList.length <= 0 && (
          <Typography sx={{ mt: 2, ml: 5, color: 'gray' }} variant="h3">
            {t('shop.label.只今、販売中の商品はありません。')}
          </Typography>
        )}
        {shopModelList !== undefined && shopModelList.length > 0 && (
          <ItemList shopModelList={shopModelList} onClick={onCardClick} />
        )}
        <Box sx={{ mt: 5, mb: 1, borderBottom: 1, borderLeft: 6, pl: 2 }}>
          <Typography variant="h4">{t(`shop.label.販売予定`)}</Typography>
        </Box>
        {scheduleModelList !== undefined && scheduleModelList.length <= 0 && (
          <Typography sx={{ mt: 2, ml: 5, color: 'gray' }} variant="h3">
            {t('shop.label.販売予定の商品はありません。')}
          </Typography>
        )}
        {scheduleModelList !== undefined && scheduleModelList.length > 0 && (
          <ScheduleList scheduleModelList={scheduleModelList} />
        )}
      </Container>
    </React.Fragment>
  );
};

export default Shop;

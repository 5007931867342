import { UserType } from 'enum';

/**
 * Emailインターフェース
 */
export default interface EmailModel {
  /**
   * ユーザータイプ
   */
  userType: UserType;
  /**
   * email
   */
  email: string;
}

/**
 * Emailモデルの初期設定
 * @param userType ユーザータイプ
 * @returns EmailModel
 */
export const initializeEmailModel = (userType: UserType): EmailModel => {
  return {
    userType: userType,
    email: '',
  };
};

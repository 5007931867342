import React from 'react';

import { LocalShipping } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardMedia,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import ItemList from 'components/itemList';
import NavigateMotion from 'components/navigateMotion';
import ShopMenuBar from 'components/shopMenuBar';
import { FaBox, FaUser } from 'react-icons/fa';
import { GiTomato } from 'react-icons/gi';
import { RiMoneyCnyBoxLine } from 'react-icons/ri';
import CommonUtils from 'utils/commonUtils';

import Order from './components/order';
import useShopItem from './useShopItem';

/**
 * 商品明細ページ
 * @returns
 */
const ShopItem = () => {
  const { t, shopModel, shopModelList, order, onPurchaseClick, onCardClick } = useShopItem();
  return (
    <React.Fragment>
      <ShopMenuBar />
      <NavigateMotion>
        <Container>
          <Grid container sx={{ p: 2, m: 2 }}>
            <Grid xs={12} sm={5} md={5}>
              <Box sx={{ boxShadow: 2, position: 'relative' }}>
                <CardMedia
                  sx={{ minHeight: 350, height: 350 }}
                  component="img"
                  image={CommonUtils.base64ImageSrc(shopModel?.salesImage)}
                />
                {shopModel.remainingStock <= 0 && (
                  <Box
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                      bgcolor: 'rgba(0, 0, 0, 0.54)',
                      color: 'white',
                      padding: '30px',
                    }}
                  >
                    <Typography variant="h3">{t('common.label.売り切れ')}</Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid xs={12} sm={7} md={7}>
              <Stack spacing={2} sx={{ mt: 1, ml: 2 }}>
                {/** ----------
                  * 商品名
                  ---------- */}
                <TextField
                  style={{ fontSize: 16 }}
                  disabled={true}
                  variant="standard"
                  label={t('shopItem.label.商品名')}
                  value={`${shopModel?.salesName}`}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    style: { fontSize: 16 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <GiTomato />
                      </InputAdornment>
                    ),
                  }}
                />
                {/** ----------
                  * 販売単価・数量
                  ---------- */}
                <TextField
                  disabled={true}
                  variant="standard"
                  label={t('shopItem.label.入り数・単位・金額')}
                  value={`${CommonUtils.conbine(
                    String(shopModel?.quantity.toLocaleString()),
                    shopModel?.unit
                  )} ￥${shopModel?.amount.toLocaleString()}`}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiMoneyCnyBoxLine />
                      </InputAdornment>
                    ),
                  }}
                />
                {/** ----------
                  * 配送方法
                  ---------- */}
                <TextField
                  disabled={true}
                  variant="standard"
                  label={t('shopItem.label.配送方法')}
                  value={`${shopModel?.memo}`}
                  multiline
                  rows={4}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalShipping />
                      </InputAdornment>
                    ),
                  }}
                />
                {/** ----------
                  * 残在庫数
                  ---------- */}
                <TextField
                  disabled={true}
                  variant="standard"
                  label={t('shopItem.label.残り数')}
                  value={`${shopModel?.remainingStock.toLocaleString()}`}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaBox />
                      </InputAdornment>
                    ),
                  }}
                />
                {/** ----------
                  * 販売元
                  ---------- */}
                <TextField
                  disabled={true}
                  variant="standard"
                  label={t('shopItem.label.販売元')}
                  value={`${shopModel?.userName}`}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaUser />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          {shopModel.remainingStock > 0 && (
            <Accordion
              sx={{
                mb: 1,
                boxShadow: 0,
                '&:before': {
                  background: 'transparent',
                },
              }}
            >
              <AccordionSummary sx={{ m: 0, p: 0 }}>
                <Box sx={{ ml: 0, borderBottom: 1, borderLeft: 6, pl: 2, borderColor: '#0044CC' }}>
                  <Typography variant="h4" color={'#0044CC'}>
                    {t('shopItem.label.注文のご希望の場合は、ココをクリック')}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Order form={order} stock={shopModel.stock} onClick={onPurchaseClick}></Order>
              </AccordionDetails>
            </Accordion>
          )}
          <Box sx={{ mb: 1, borderBottom: 1, borderLeft: 6, pl: 2 }}>
            <Typography variant="h4">{t(`shopItem.label.販売元の商品`)}</Typography>
          </Box>
          <ItemList shopModelList={shopModelList} onClick={onCardClick} />
        </Container>
      </NavigateMotion>
    </React.Fragment>
  );
};

export default ShopItem;

import { SubmitHandler, useForm } from 'react-hook-form';

/**
 * 検索テキストのカスタムフック
 * @param props 引数
 * @returns
 */
const useSearchTextField = (props: { onSearchClick: (e: { searchText: string }) => void }) => {
  interface InputForm {
    searchText: string;
  }

  // useFormの設定
  const { register, handleSubmit } = useForm<InputForm>({
    shouldUnregister: true,
  });

  /**
   * 検索をクリック
   * @param data formData
   */
  const onSearchClick: SubmitHandler<InputForm> = async (data) => {
    console.log(`onSearchClick start ${JSON.stringify(data)}`);
    props.onSearchClick(data);
  };

  return {
    register,
    handleSubmit,
    onSearchClick,
  };
};

export default useSearchTextField;

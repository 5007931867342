import axios, { AxiosError, Method } from 'axios';

import StorageUtils from './storageUtils';

/**
 * API起動の戻り
 */
export interface Result<T> {
  /**
   * ステータスコード
   */
  status: number;
  /**
   * データ
   */
  data?: T;
  /**
   * エラー内容
   */
  error?: AxiosError;
}

/**
 * 通信処理
 * @name apiAccess
 * @param {string} method メソッド
 * @param {string} url 通信先URL
 * @param {unknown} params パラメータ
 */
export const apiAccess = async <T>(
  method: Method,
  url: string,
  params?: unknown
): Promise<Result<T>> => {
  console.log(`url: ${process.env.REACT_APP_API_URL}${url}`);
  return new Promise((resolve) =>
    axios({
      method: method,
      baseURL: process.env.REACT_APP_API_URL,
      url: url,
      data: params,
      headers: getHeaders(),
      responseType: 'json',
    })
      .then((res) => {
        resolve({ status: res.status, data: res.data as T });
      })
      .catch((err) => {
        if (err.response !== undefined) {
          resolve({ status: err.response.status, error: err });
        } else {
          resolve({ status: 500 });
        }
      })
  );
};

/**
 * apiヘッダーを取得する
 * @returns headers
 */
/* eslint-disable */
const getHeaders = (): any => {
  // storeからtokenを取得する
  const token = StorageUtils.getToken();
  if (token == null) {
    return {
      'Content-Type': 'application/json',
    };
  }
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

import React from 'react';

import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CalendarMonth, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CardActionArea,
  CardMedia,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { PurchaseType } from 'enum';
import PurchaseModel from 'models/purchase';
import SalesModel from 'models/sales';
import CommonUtils from 'utils/commonUtils';

import usePurchaseHistoryItem from './usePurchaseHistoryItem';

/**
 * 購入履歴を表示する
 * @param props 購入希望リスト、販売リスト
 * @returns
 */
const PurchaseHistoryItem = (props: {
  purchaseModel: PurchaseModel;
  salesModel: SalesModel;
  onImageClick: (e: { userId: number; salesId: number }) => void;
  onCancelClick: (e: { purchaseId: number }) => void;
}) => {
  const { t, onImageClick, onCancelClick, getPurchaseTypeName, getPurchaseColor } =
    usePurchaseHistoryItem(props);
  return (
    <Paper sx={{ mt: 2, boxShadow: 1 }}>
      <Grid container>
        <Grid xs={12} sm={3} md={3}>
          <Box sx={{ boxShadow: 0, position: 'relative' }}>
            <CardActionArea onClick={onImageClick}>
              <CardMedia
                sx={{ p: 1, minHeight: 200, height: 200 }}
                component="img"
                image={CommonUtils.base64ImageSrc(props.salesModel.salesImage)}
              ></CardMedia>
            </CardActionArea>
          </Box>
        </Grid>
        <Grid xs={12} sm={7} md={7}>
          <Stack spacing={0} sx={{ mt: 1, ml: 2 }}>
            <Typography variant="h3" color="text.primary">
              {props.salesModel.salesName}
            </Typography>

            {props.purchaseModel.purchaseType !== PurchaseType.untreated && (
              <Typography
                fontWeight={'bold'}
                variant="h6"
                color={getPurchaseColor(props.purchaseModel.purchaseType)}
              >
                {getPurchaseTypeName(props.purchaseModel.purchaseType)}
              </Typography>
            )}
          </Stack>
          <Stack spacing={2} sx={{ mt: 1, ml: 2 }}>
            {/** ----------
              * 配達希望日
              ---------- */}
            <TextField
              disabled={true}
              variant="standard"
              label={t('purchaseHistoryItem.label.配達希望日')}
              value={`${CommonUtils.dateFormat(props.purchaseModel.desiredDeliveryDate)}`}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarMonth />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
              * 購入日
              ---------- */}
            <TextField
              disabled={true}
              variant="standard"
              label={t('purchaseHistoryItem.label.購入日')}
              value={`${CommonUtils.dateFormat(props.purchaseModel.purchaseDateTime)}`}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarMonth />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Grid>
        <Grid xs={12} sm={2} md={2}>
          {props.purchaseModel.purchaseType === PurchaseType.untreated && (
            <Button onClick={onCancelClick} variant="outlined" color="error" sx={{ m: 1, p: 2 }}>
              {t('purchaseHistoryItem.label.キャンセル')}
            </Button>
          )}
        </Grid>
      </Grid>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography sx={{ ml: 1 }} variant="h6">
            {t('purchaseHistoryItem.label.その他・要望')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pr: 0 }}>
          {/* その他・要望 */}
          <Stack sx={{ ml: 2, mr: 2 }}>
            <TextField
              disabled={true}
              value={`${props.purchaseModel.memo}`}
              multiline
              rows={5}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faFileLines} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default PurchaseHistoryItem;

import CropsModel from 'models/crops';
import ScheduleModel from 'models/schedule';
import { Result, apiAccess } from 'utils/apiUtils';

/**
 * ログインユーザーの作物情報を取得する
 * @returns 作物モデル
 */
export const getCropsList = async (): Promise<Result<CropsModel[]>> => {
  return await apiAccess<CropsModel[]>('GET', 'crops/list');
};

/**
 * ログインユーザーと作物名で作物情報を取得する
 * @param cropsName 作物名
 * @returns 作物モデル
 */
export const getCropsListByCropsName = async (cropsName: string): Promise<Result<CropsModel[]>> => {
  return await apiAccess<CropsModel[]>('GET', `crops/list/cropsName/${cropsName}`);
};

/**
 * 作物IDから作物情報を取得する
 * @param cropsId 作物ID
 */
export const getCrops = async (cropsId: number): Promise<Result<CropsModel>> => {
  return await apiAccess<CropsModel>('GET', `crops/${cropsId}`);
};

/**
 * 保存APIを起動する
 * @param model 作物モデル
 */
export const saveCrops = async (model: CropsModel): Promise<Result<CropsModel>> => {
  if (model.cropsId === 0) {
    return await apiAccess('POST', 'crops', model);
  } else {
    return await apiAccess('PUT', 'crops', model);
  }
};

/**
 * 作物の削除
 * @param cropsId 作物ID
 * @param version バージョンID
 * @returns バージョンID
 */
export const removeCrops = async (cropsId: number, version: number) => {
  return await apiAccess('DELETE', `crops/${cropsId}/${version}`);
};

/**
 * 販売予定リストを取得する
 * @returns 販売予定リスト
 */
export const getScheduleList = async (): Promise<Result<ScheduleModel[]>> => {
  return await apiAccess('GET', 'crops/schedulelist');
};

import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

/**
 * 注文入力の値
 */
export interface OrderForm {
  desiredDeliveryDate: Date;
  orderNumber: number;
  memo: string;
}

/**
 * OrderFormの初期化
 * @returns OrderFormの初期化
 */
export const initializeOrderForm = (): OrderForm => {
  return {
    orderNumber: 0,
    desiredDeliveryDate: new Date(),
    memo: '',
  };
};

/**
 * Order.tsxのカスタムフック
 * @returns クロージャ
 */
const useOrder = (props: {
  form: OrderForm;
  stock: number;
  onClick: (e: { desiredDeliveryDate: Date; orderNumber: number; memo: string }) => void;
}) => {
  // 多言語化対応
  const { t } = useTranslation();
  // バリデーションルール
  const schema = yup.object().shape({
    orderNumber: yup
      .number()
      .required(t('order.message.購入希望数を入力してください。'))
      .min(1, t('order.message.購入希望数を入力してください。'))
      .max(
        props.stock,
        `${t('order.message.{0}文字以下で入力してください。').replace(
          '{0}',
          props.stock.toLocaleString()
        )}`
      ),
    memo: yup.string().max(200, t('order.message.200文字以下で入力してください。')),
  });

  // useFormの設定
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<OrderForm>({
    resolver: yupResolver(schema),
    defaultValues: props.form,
    shouldUnregister: true,
  });

  // 他で変更された場合は、設定する
  useEffect(() => {
    setValue('desiredDeliveryDate', props.form.desiredDeliveryDate);
    setValue('orderNumber', props.form.orderNumber);
    setValue('memo', props.form.memo);
  }, [props.form]);

  /**
   * 購入希望を送信をクリック
   * @param data formData
   */
  const onPrchaseClick: SubmitHandler<OrderForm> = async (data) => {
    console.log(`onPurchaseClick start ${JSON.stringify(data)}`);
    props.onClick(data);
  };

  return {
    t,
    control,
    register,
    errors,
    handleSubmit,
    onPrchaseClick,
  };
};
export default useOrder;

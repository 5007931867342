import React from 'react';

import { Card, CardMedia, CardContent, Typography, CardActionArea, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ShopModel from 'models/shop';
import CommonUtils from 'utils/commonUtils';

import { useItem } from './useItem';

/**
 * 商品のコンポーネント
 */
const Item = (props: {
  shopModel: ShopModel;
  onClick?: (e: { userId: number; salesId: number }) => void;
}) => {
  // カスタムフック定義
  const { t, onItemClick } = useItem(props);

  return (
    <Card sx={{ m: 1 }}>
      <Grid container>
        <CardActionArea onClick={onItemClick}>
          <Box sx={{ position: 'relative' }}>
            <CardMedia
              component="img"
              height="160"
              image={CommonUtils.base64ImageSrc(props.shopModel.salesImage)}
              alt={''}
            />
            {props.shopModel.remainingStock <= 0 && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  bgcolor: 'rgba(0, 0, 0, 0.54)',
                  color: 'white',
                  padding: '10px',
                }}
              >
                <Typography variant="h5">{t('common.label.売り切れ')}</Typography>
              </Box>
            )}
          </Box>
          <CardContent>
            {/* 商品名 */}
            <Grid xs={12}>
              <Typography
                sx={{ minHeight: 25, maxHeight: 25 }}
                variant="h4"
                overflow="hidden"
                gutterBottom
              >
                {props.shopModel.salesName}
              </Typography>
            </Grid>
            {/* 農家名 */}
            <Grid xs={12}>
              <Typography sx={{ minHeight: 23, maxHeight: 23 }} variant="h5" overflow="hidden">
                {props.shopModel.userName}
              </Typography>
            </Grid>
            {/* 詳細 */}
            <Grid xs={12}>
              <Typography
                sx={{ minHeight: 45, maxHeight: 45 }}
                variant="h6"
                color="text.secondary"
                overflow="hidden"
              >
                {props.shopModel.memo}
              </Typography>
            </Grid>
            {/* 数量／単価 */}
            <Grid xs={12}>
              <Typography
                variant={'h6'}
                sx={{ minHeight: 23, maxHeight: 23 }}
                textAlign={'right'}
                gutterBottom
              >
                {CommonUtils.conbine(
                  props.shopModel.quantity.toLocaleString(),
                  props.shopModel.unit,
                  ' '
                )}
                {` ￥`}
                {props.shopModel.amount.toLocaleString()}
              </Typography>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Grid>
    </Card>
  );
};

export default Item;

/**
 * 店情報モデル
 */
export default interface ShopModel {
  /**
   * 販売キー（主キー）
   */
  salesId: number;
  /**
   * ユーザーキー（農家）
   */
  userId: number;
  /**
   * 作物キー
   */
  cropsId: number;
  /**
   * ファイルイメージ
   */
  salesImageFile?: File;
  /**
   * ファイルイメージ（文字列）
   */
  salesImage?: string;
  /**
   * 名前
   */
  salesName: string;
  /**
   * 販売日
   */
  salesDate?: Date;
  /**
   * 販売金額
   */
  amount: number;
  /**
   * 入り数
   */
  quantity: number;
  /**
   * 販売単位
   */
  unit: string;
  /**
   * 注文可能数
   */
  stock: number;
  /**
   * 残在庫
   */
  remainingStock: number;
  /**
   * 販売方法
   */
  memo: string;
  /**
   * 注文確定数
   */
  confirmCount: number;
  /**
   * 未処理数
   */
  untreatedCount: number;
  /**
   * ユーザー名
   */
  userName: string;
}

/**
 * 作物モデルの初期設定値を取得する
 * @returns 作物モデル
 */
export const initializeShopModel = (): ShopModel => {
  return {
    salesId: 0,
    userId: 0,
    cropsId: 0,
    salesImageFile: undefined,
    salesImage: undefined,
    salesName: '',
    amount: 0,
    quantity: 0,
    unit: '',
    stock: 0,
    remainingStock: 0,
    memo: '',
    confirmCount: 0,
    untreatedCount: 0,
    userName: '',
  };
};

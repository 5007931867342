import React from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, InputAdornment, Stack, TextField } from '@mui/material';
import CalendarTextField from 'components/calendarTextField';
import { FiSend } from 'react-icons/fi';
import { RiScales2Fill } from 'react-icons/ri';

import useOrder, { OrderForm } from './useOrder';

/**
 * 注文画面
 * @param props stock: 在庫数、onClick: クリックイベント
 * @returns
 */
const Order = (props: {
  form: OrderForm;
  stock: number;
  onClick: (e: { desiredDeliveryDate: Date; orderNumber: number; memo: string }) => void;
}) => {
  const { t, control, register, errors, handleSubmit, onPrchaseClick } = useOrder({ ...props });
  return (
    <React.Fragment>
      <Container sx={{ mt: 4, mb: 5 }} component="form" onSubmit={handleSubmit(onPrchaseClick)}>
        <Stack spacing={3}>
          {/** ----------
             * 配達希望日
             ---------- */}
          <CalendarTextField
            name="desiredDeliveryDate"
            control={control}
            label={t('order.label.配達希望日')}
            error={'desiredDeliveryDate' in errors}
            helperText={errors.desiredDeliveryDate?.message}
          />

          {/** ----------
            * 購入希望数
            ---------- */}
          <Controller
            name="orderNumber"
            control={control}
            render={({ field: { name, value, onBlur, onChange } }) => (
              <NumericFormat
                type="tel"
                name={name}
                value={value}
                onBlur={onBlur}
                onValueChange={(v) => onChange(v.floatValue)}
                allowNegative={false}
                thousandSeparator=","
                customInput={TextField}
                error={'orderNumber' in errors}
                helperText={errors.orderNumber?.message}
                label={t('order.label.購入希望数')}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <RiScales2Fill />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          {/** ----------
            * その他・要望
            ---------- */}
          <TextField
            {...register('memo')}
            error={'memo' in errors}
            helperText={errors.memo?.message}
            label={t('order.label.その他・要望')}
            multiline
            rows={5}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faFileLines} />
                </InputAdornment>
              ),
            }}
          />
          <Button color="primary" startIcon={<FiSend />} type="submit" variant="contained">
            {t('order.label.注文を送信する')}
          </Button>
        </Stack>
      </Container>
    </React.Fragment>
  );
};
export default Order;

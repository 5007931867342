import * as React from 'react';

import { AppBar, BottomNavigation, CssBaseline, Toolbar } from '@mui/material';

/**
 * BottomAppBarの引き数
 */
interface BottomAppBarProps {
  /**
   * 子ノード
   */
  children?: React.ReactNode;
  /**
   * ボタンのクリックイベント
   * @param index index
   * @returns void
   */
  onChanged?: (index: number) => void;
}

/**
 * BottomAppBar Component
 * @returns ReactNode
 */
const BottomAppBar = ({ children = undefined, onChanged = undefined }: BottomAppBarProps) => {
  return (
    <React.Fragment>
      <Toolbar />
      <CssBaseline />
      <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <BottomNavigation
          showLabels
          onChange={(event, value) => {
            if (onChanged) {
              onChanged(value as number);
            }
          }}
        >
          {/**
           * 子ノードを表示する
           */}
          {children}
        </BottomNavigation>
      </AppBar>
    </React.Fragment>
  );
};

export default BottomAppBar;

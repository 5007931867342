import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogType } from 'enum';

/**
 * メッセージダイアログの引数
 */
export interface MessageDialogConfig {
  /**
   * メッセージ
   */
  message: string;
  /**
   * ダイアログ種類
   */
  dialogType: DialogType;
  /*
   * ボタンが押されるまで、待つ用
   */
  resolve: (bool: boolean) => void;
}

/**
 * メッセージダイアログ設定の初期値
 * @returns 初期値
 */
export const initializeMessageDialogConfig = (): MessageDialogConfig => {
  return {
    message: '',
    dialogType: DialogType.DisplayNone,
    resolve: () => {
      return;
    },
  };
};

/**
 * メッセージダイアログ引き数
 * @name MessageDialogProps
 */
export type MessageDialogProps = {
  messageDialogConfig: MessageDialogConfig;
};

/**
 * メッセージダイアログのカスタムフック
 * @param props メッセージダイアログの引数
 * @returns
 */
const useMessageDialog = (props: MessageDialogProps) => {
  const { t } = useTranslation();
  // true: 開く、false: 閉じる
  const [open, setOpen] = useState(false);

  // useEffect
  useEffect(() => {
    if (props.messageDialogConfig.dialogType === DialogType.DisplayNone) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [props.messageDialogConfig]);

  /**
   * OKクリックイベント
   */
  const onOkClick = async () => {
    setOpen(false);
    props.messageDialogConfig.resolve(true);
  };

  /**
   * キャンセルクリックイベント
   */
  const onCancelClick = () => {
    setOpen(false);
    props.messageDialogConfig.resolve(false);
  };

  return {
    t,
    open,
    onOkClick,
    onCancelClick,
  };
};

export default useMessageDialog;

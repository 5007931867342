import React, { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { getPurchaseListBySalesId } from 'api/purchase';
import { getSales, removeSales, saveSales } from 'api/sales';
import { DialogType } from 'enum';
import { Path, StatusCode } from 'enum';
import useCommon from 'features/useCommon';
import PurchaseModel from 'models/purchase';
import SalesModel, { initializeSalesModel } from 'models/sales';
import * as yup from 'yup';

/**
 * Salesのカスタムフック
 * @returns
 */
const useSales = () => {
  // imageBase64
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  // 共通フック
  const { t, navigate, location, showMessage, subsequentApiProcess, enqueueSnackbar, loading } =
    useCommon();

  const cropsId = location.state.cropsId as number;
  const salesId = (location.state.salesId as number) ?? 0;
  const [isNew, setIsNew] = useState(true);

  // バリデーションルール
  const schema = yup.object().shape({
    salesName: yup
      .string()
      .required(t('sales.message.名前を入力してください。'))
      .max(50, t('sales.message.50文字以下で入力してください。')),
    amount: yup
      .number()
      .required(t('sales.message.販売金額を入力してください。'))
      .min(
        1,
        t('sales.message.1~{0}で入力してください。').replace(
          '{0}',
          Number(9999999).toLocaleString()
        )
      )
      .max(
        9999999,
        t('sales.message.1~{0}で入力してください。').replace(
          '{0}',
          Number(9999999).toLocaleString()
        )
      ),
    quantity: yup
      .number()
      .required(t('sales.message.入り数を入力してください。'))
      .min(
        1,
        t('sales.message.1~{0}で入力してください。').replace('{0}', Number(9999).toLocaleString())
      )
      .max(
        9999,
        t('sales.message.1~{0}で入力してください。').replace('{0}', Number(9999).toLocaleString())
      ),
    unit: yup
      .string()
      .required(t('sales.message.単位を入力してください。'))
      .max(50, t('sales.message.50文字以下で入力してください。')),
    stock: yup
      .number()
      .required(t('sales.message.注文可能数を入力してください。'))
      .min(
        1,
        t('sales.message.1~{0}で入力してください。').replace('{0}', Number(99999).toLocaleString())
      )
      .max(
        99999,
        t('sales.message.1~{0}で入力してください。').replace('{0}', Number(99999).toLocaleString())
      ),
    memo: yup
      .string()
      .required(t('sales.message.販売方法を入力してください。'))
      .max(200, t('sales.message.200文字以下で入力してください。')),
  });

  // useFormの設定
  const {
    control,
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<SalesModel>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: async () => await fetchSales(),
  });

  /**
   * 販売情報を取得する
   * @returns salesModel 販売情報
   */
  const fetchSales = async (): Promise<SalesModel> => {
    loading(true);
    let salesModel = initializeSalesModel(cropsId);
    if (salesId !== 0) {
      const result = await getSales(salesId);
      subsequentApiProcess(result.status, () => {
        salesModel = result.data as SalesModel;
        if (salesModel.salesImage) {
          setAvatar(salesModel.salesImage);
        }
      });
      setIsNew(false);
    }
    loading(false);
    console.log(`fetchSales ${JSON.stringify(salesModel)}`);
    return salesModel;
  };

  /**
   * 単位のChipクリックイベント
   * @param label 作業区分
   */
  const unitChipClick = (label: string) => {
    console.debug(`unitChipClick start`);
    resetField('unit');
    setValue('unit', label);
    console.debug(`unitChipClick end`);
  };

  /**
   * アバターのチェンジイベント
   * @param e event
   */
  const imageChange = useCallback(async (e: { file: File; file64: string }) => {
    console.debug(`imageChange start ${e}`);
    setValue('salesImage', e.file64);
    console.debug(`imageChange end`);
  }, []);

  /**
   * 保存をクリック
   * @param e event
   */
  const onSaveClick: SubmitHandler<SalesModel> = async (data) => {
    console.debug(`saveClick start e: ${JSON.stringify(data)}`);
    loading(true);
    await save(data);
    loading(false);
    console.debug(`saveClick end`);
  };

  /**
   * 野菜一覧をクリック
   * @param e event
   */
  const onYasaiClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`cropsListClick start e: ${e}`);
    reset();
    // 野菜一覧へ遷移する
    navigate(Path.cropsList);
    console.debug(`cropsListClick end`);
  };

  /**
   * 削除ボタンをクリック
   * @param e event
   */
  const onDeleteClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`onDeleteClick start e: ${e}`);
    if (await showMessage(t(`common.message.削除してもよろしいですか？`), DialogType.OkCancel)) {
      loading(true);
      if (await canRemove()) {
        await remove();
      }
      loading(false);
    }
    console.debug(`onDeleteClick end`);
  };

  /**
   * 新規登録処理
   * @param data salesModel
   */
  const save = async (data: SalesModel): Promise<void> => {
    const result = await saveSales(data);
    subsequentApiProcess(result.status, () => {
      // 更新データの格納
      if (data.salesId === 0) {
        setValue('userId', result.data?.userId as number);
        setValue('salesId', result.data?.salesId as number);
      }
      setValue('updateDateTime', result.data?.updateDateTime as Date);
      setValue('version', result.data?.version as number);
      // メッセージの格納
      enqueueSnackbar(t(`common.message.保存しました。`), { variant: 'success' });
      setIsNew(false);
    });
  };

  /**
   * 削除できるか検証する
   * @returns true: 削除できる、false: 削除できない
   */
  const canRemove = async () => {
    if (await hasPurchase()) {
      await showMessage(t(`sales.message.購入希望のデータが存在するため、削除できませんでした。`));
      return false;
    } else {
      return true;
    }
  };

  /**
   * 削除処理
   */
  const remove = async () => {
    console.log(`remove start`);

    const salesId = getValues('salesId');
    const version = getValues('version');
    const result = await removeSales(salesId, version);
    subsequentApiProcess(result.status, () => {
      enqueueSnackbar(t('common.message.削除しました。'), { variant: 'success' });
      navigate(Path.cropsList);
    });

    console.log(`remove end`);
  };

  /**
   * 購入情報を取得する
   * @returns true: データある、false: データない
   */
  const hasPurchase = async () => {
    const salesId = getValues('salesId');
    const result = await getPurchaseListBySalesId(salesId);
    if (result.status === StatusCode.Success) {
      const data = result.data as PurchaseModel[];
      return data.length === 0 ? false : true;
    }
    return false;
  };

  return {
    t,
    avatar,
    control,
    errors,
    register,
    handleSubmit,
    isNew,
    imageChange,
    unitChipClick,
    onYasaiClick,
    onSaveClick,
    onDeleteClick,
  };
};

export default useSales;

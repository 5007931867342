import AddressModel from 'models/address';
import { Result, apiAccess } from 'utils/apiUtils';

/**
 * 郵便番号から住所を取得する
 * @param postalCode 郵便番号
 * @returns 住所モデル
 */
export const getAddress = async (postalCode: string): Promise<Result<AddressModel>> => {
  return await apiAccess<AddressModel>('GET', `address/${postalCode}`);
};

import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Typography,
} from '@mui/material';
import { DialogType } from 'enum';

import useMessageDialog, { MessageDialogProps } from './useMessageDialog';

/**
 * メッセージダイアログ
 * @param props MessageDialogProps
 * @returns
 */
const MessageDialog = (props: MessageDialogProps) => {
  const { t, open, onOkClick, onCancelClick } = useMessageDialog(props);

  /**
   * 表示項目の作成
   */
  const createDisplayedItem = () => {
    switch (props.messageDialogConfig.dialogType) {
      case DialogType.DisplayNone:
        return <DialogActions></DialogActions>;
      case DialogType.OkOnly:
        return (
          <React.Fragment>
            <DialogActions>
              <Button variant="contained" onClick={onOkClick} color="primary" size="small">
                <Typography variant="button">{t('common.label.OK')}</Typography>
              </Button>
            </DialogActions>
          </React.Fragment>
        );
      case DialogType.OkCancel:
        return (
          <React.Fragment>
            <DialogActions>
              <Button variant="text" onClick={onCancelClick} color="primary" size="small">
                {t('common.label.Cancel')}
              </Button>
              <Button variant="contained" onClick={onOkClick} color="primary" size="small">
                <Typography variant="button">{t('common.label.OK')}</Typography>
              </Button>
            </DialogActions>
          </React.Fragment>
        );
      default:
        return;
    }
  };

  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
            {props.messageDialogConfig.message}
          </DialogContentText>
        </DialogContent>
        {createDisplayedItem()}
      </Dialog>
    </React.Fragment>
  );
};

export default MessageDialog;

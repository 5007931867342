import React from 'react';

import { Add, Storefront, Person } from '@mui/icons-material';
import { Badge, BottomNavigationAction, Container } from '@mui/material';
import BottomAppBar from 'components/bottomAppBar';
import MenuAppBar from 'components/menuBar';
import NavigateMotion from 'components/navigateMotion';
import SearchTextField from 'components/searchTextField';
import CropsItem from 'features/cropsList/components/cropsItem';

import useCropsList from './useCropsList';

/**
 * 農作物一覧ページ
 * @returns
 */
const CropsList = () => {
  const {
    t,
    cropsModelList,
    getCultivationModelList,
    getSalesModelList,
    untreatedCount,
    onAddCropsClick,
    onPotentialBuyerClick,
    onFarmerClick,
    onSearchClick,
  } = useCropsList();
  return (
    <React.Fragment>
      <NavigateMotion>
        <MenuAppBar>
          <SearchTextField
            placeholder={t('cropsList.label.やさい名で検索する')}
            onSearchClick={onSearchClick}
          ></SearchTextField>
        </MenuAppBar>

        <Container>
          {cropsModelList?.map((cropsModel) => {
            return (
              <CropsItem
                key={cropsModel.cropsId}
                cropsModel={cropsModel}
                cultivationModelList={getCultivationModelList(cropsModel.cropsId)}
                salesModelList={getSalesModelList(cropsModel.cropsId)}
              />
            );
          })}
        </Container>
      </NavigateMotion>

      <BottomAppBar>
        <BottomNavigationAction
          label={t('menu.やさい追加')}
          icon={<Add />}
          onClick={onAddCropsClick}
        />
        <BottomNavigationAction
          label={t('menu.購入希望')}
          icon={
            <Badge badgeContent={untreatedCount} color="primary">
              <Storefront />
            </Badge>
          }
          onClick={onPotentialBuyerClick}
        />
        <BottomNavigationAction
          label={t('menu.農家情報')}
          icon={<Person />}
          onClick={onFarmerClick}
        />
      </BottomAppBar>
    </React.Fragment>
  );
};

export default CropsList;

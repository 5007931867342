import { SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { resetPassword } from 'api/tempUser';
import { hasEmail } from 'api/user';
import { Path, UserType } from 'enum';
import useCommon from 'features/useCommon';
import EmailModel, { initializeEmailModel } from 'models/email';
import * as yup from 'yup';

/**
 * Emailのカスタムフック
 * @param userType : ユーザー種類
 * @returns
 */
const useEmail = (props: { userType: UserType }) => {
  // 共通フック
  const { t, navigate, showMessage, enqueueSnackbar, subsequentApiProcess, loading } = useCommon();

  // バリデーションルール
  const schema = yup.object({
    email: yup
      .string()
      .required(t('email.message.メールアドレスを入力してください。'))
      .email(t('email.message.メールアドレスを入力してください。')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EmailModel>({
    resolver: yupResolver(schema),
    defaultValues: initializeEmailModel(props.userType),
  });

  /**
   * メール送信ボタンクリック
   * @param data EmailModel
   */
  const onSubmit: SubmitHandler<EmailModel> = async (data) => {
    console.log(`onSubmit data: ${data}`);

    loading(true);

    const result = await hasEmail(data.userType, data.email);
    subsequentApiProcess(result.status, async () => {
      if (!result.data?.hasEmail) {
        await showMessage(
          t('email.message.メールアドレスが登録されていません。メールアドレスを確認してください。')
        );
        loading(false);
        return;
      }
      await sendMail(data);
      loading(false);
    });
  };

  /**
   * 変更用のメールを送信する。
   * @param emailModel EmailModel
   */
  const sendMail = async (emailModel: EmailModel) => {
    console.log(`sendMail start`);
    const result = await resetPassword(emailModel);
    subsequentApiProcess(result.status, async () => {
      enqueueSnackbar(
        'email.message.パスワード変更のメールを送信しました。メールをご確認ください。',
        { variant: 'success' }
      );
      navigateSignin();
    });
    console.log(`sendMail end`);
  };

  /**
   * ログインへクリックする
   * @param e event
   */
  const onSigninClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log(`signinClick start ${e}`);
    navigateSignin();
    console.log(`signinClick end`);
  };

  /**
   * サインインに画面遷移
   */
  const navigateSignin = () => {
    if (props.userType === UserType.farmer) {
      navigate(Path.farmerSignin);
    } else {
      navigate(Path.buyerSignin);
    }
  };

  return {
    t,
    register,
    handleSubmit,
    errors,
    onSubmit,
    onSigninClick,
  };
};

export default useEmail;

import { UserType } from 'enum';

/**
 * ユーザーのインターフェス
 */
export default interface UserModel {
  /**
   * ユーザーID
   */
  userId: number;
  /**
   * ユーザータイプ
   */
  userType: UserType;
  /**
   * ファイルイメージ
   */
  userImageFile?: File;
  /**
   * ファイルイメージ（文字列）
   */
  userImage?: string;
  /**
   * 名前
   */
  userName: string;
  /**
   * メールアドレス
   */
  email: string;
  /**
   * パスワード
   */
  password?: string;
  /**
   * パスワードの確認
   */
  comfirmPassword?: string;
  /**
   * 郵便番号
   */
  postalCode?: string;
  /**
   * 都道府県
   */
  prefecture: string;
  /**
   * 市区町村
   */
  municipality: string;
  /**
   * 丁目番地号
   */
  streetNumber: string;
  /**
   * 建物
   */
  building?: string;
  /**
   * 電話番号
   */
  phoneNumber: string;
  /**
   * 公開する
   */
  isPublish: boolean;
  /**
   * 更新日時
   */
  updateDateTime: Date;
  /**
   * バージョン
   */
  version: number;
}

/**
 * ユーザーモデルの初期値を取得する
 * @returns ユーザーモデル
 */
export const initializeUserModel = (userType: UserType = UserType.farmer): UserModel => {
  return {
    userId: 0,
    userType: userType,
    userImageFile: undefined,
    userImage: undefined,
    userName: '',
    email: '',
    password: '',
    comfirmPassword: '',
    postalCode: '',
    prefecture: '',
    municipality: '',
    streetNumber: '',
    building: '',
    phoneNumber: '',
    isPublish: true,
    updateDateTime: new Date(),
    version: 0,
  };
};

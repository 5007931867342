import React, { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { getAddress } from 'api/address';
import { saveTempUser } from 'api/tempUser';
import { getUser, hasEmail, updateUser } from 'api/user';
import { Path, StatusCode, UserType } from 'enum';
import useCommon from 'features/useCommon';
import AddressModel from 'models/address';
import UserModel, { initializeUserModel } from 'models/user';
import * as yup from 'yup';

/**
 * Userのカスタムフック
 * @returns
 */
const useUser = (props: { isNew: boolean; userType: UserType }) => {
  // imageBase64
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  const { t, navigate, subsequentApiProcess, showMessage, enqueueSnackbar, loading } = useCommon();
  // バリデーションルール
  const schema = yup.object().shape({
    userName: yup
      .string()
      .required(t('user.message.名前を入力してください。'))
      .max(50, t('user.message.50文字以下で入力してください。')),
    email: yup
      .string()
      .required(t('user.message.メールアドレスを入力してください。'))
      .email(t('user.message.メールアドレスを入力してください。')),
    password: yup
      .string()
      .required(t('user.message.パスワードを入力してください。'))
      .min(8, t('password.message.8文字以上36文字以下で入力してください。'))
      .max(36, t('password.message.8文字以上36文字以下で入力してください。')),
    comfirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t(`user.message.パスワードが一致していません。`)),
    postalCode: yup
      .string()
      .required(t('user.message.郵便番号を入力してください。'))
      .length(7, t('user.message.郵便番号を入力してください。')),
    prefecture: yup.string().required(t('user.message.都道府県を入力してください。')),
    municipality: yup.string().required(t('user.message.市区町村を入力してください。')),
    streetNumber: yup.string().required(t('user.message.丁目番地号を入力してください。')),
    building: yup.string().max(50, t('user.message.50文字以下で入力してください。')),
    phoneNumber: yup
      .string()
      .required(t('user.message.電話番号を入力してください。'))
      .min(10, t('user.message.10文字以上11文字以下で入力してください。'))
      .max(11, t('user.message.10文字以上11文字以下で入力してください。')),
  });

  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserModel>({
    resolver: yupResolver(schema),
    defaultValues: async () => await fetchUser(),
  });

  /**
   * ユーザー情報を取得する
   */
  const fetchUser = async (): Promise<UserModel> => {
    if (props.isNew) {
      return initializeUserModel(props.userType);
    }
    loading(true);
    let userModel = initializeUserModel(props.userType);

    const result = await getUser();
    subsequentApiProcess(result.status, () => {
      // 通信成功
      userModel = result.data as UserModel;
      if (userModel.userImage) {
        setAvatar(`${userModel.userImage}`);
      }
    });
    loading(false);
    return userModel;
  };

  /**
   * タイトルを取得する
   * @returns タイトル
   */
  const getTitle = (): string => {
    const temp = props.isNew ? 'Temp' : '';
    if (props.userType === UserType.farmer) {
      return t(`user.title.farmer${temp}`);
    } else {
      return t(`user.title.buyer${temp}`);
    }
  };

  /**
   * アバターのチェンジイベント
   * @param file File
   * @param file64 base64
   */
  const imageChange = useCallback(async (e: { file: File; file64: string }) => {
    console.debug(`imageChange start ${e}`);
    setValue('userImage', e.file64);
    console.debug(`imageChange end`);
  }, []);

  /**
   * 野菜一覧をクリック
   * @param e event
   */
  const onYasaiClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`yasaiClick start e: ${e}`);
    reset();
    // 野菜一覧へ遷移する
    navigate(Path.cropsList);
    console.debug(`yasaiClick end`);
  };

  /**
   * 保存ボタンクリック
   * @param e event
   */
  const onSaveClick: SubmitHandler<UserModel> = async (data) => {
    console.debug(`saveClick start e: ${JSON.stringify(data)}`);
    loading(true);
    if (!(await validation(data))) {
      loading(false);
      return;
    }
    if (props.isNew) {
      await tempSave(data);
    } else {
      await save(data);
    }
    loading(false);
    console.debug(`saveClick end`);
  };

  /**
   * ログイン画面へ
   * @param e event
   */
  const onSigninClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`signinClick start e: ${e}`);
    reset();
    if (props.userType === UserType.farmer) {
      // 野菜一覧へ遷移する
      navigate(Path.farmerSignin);
    } else {
      // 野菜一覧へ遷移する
      navigate(Path.buyerSignin);
    }
    console.debug(`signinClick end`);
  };

  /**
   * 郵便番号から住所検索
   * @param e event
   */
  const onZipClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`onZipClick start e: ${e}`);
    const postalCode = getValues('postalCode');
    if (postalCode?.toString().length !== 7) {
      await showMessage(t('user.message.郵便番号を入力してください。'));
      return;
    }
    loading(true);
    const result = await getAddress(postalCode);
    if (result.status === StatusCode.Success) {
      // 住所を設定する
      const addressModel = result.data as AddressModel;
      setValue('prefecture', addressModel.prefecture);
      setValue('municipality', addressModel.municipality);
      setValue('streetNumber', addressModel.streetNumber);
    } else {
      enqueueSnackbar(t('user.message.住所が取得できませんでした。'), { variant: 'error' });
    }
    loading(false);
    console.debug(`onZipClick end`);
  };

  /**
   * サーバー検証処理
   * @param data userModel
   * @returns true: 正常、false: 異常
   */
  const validation = async (data: UserModel): Promise<boolean> => {
    const result = await hasEmail(data.userType, data.email);
    if (result.data?.hasEmail) {
      await showMessage(t(`user.message.このメールアドレスは既に登録されてます。`));
      return false;
    }
    return true;
  };

  /**
   * 保存処理
   * @param data userModel
   * @returns userModel
   */
  const save = async (data: UserModel): Promise<void> => {
    const result = await updateUser(data);
    subsequentApiProcess(result.status, () => {
      // 通信成功
      setValue('userId', result.data?.userId as number);
      setValue('updateDateTime', result.data?.updateDateTime as Date);
      setValue('version', result.data?.version as number);
      enqueueSnackbar(t(`common.message.保存しました。`), { variant: 'success' });
    });
  };

  /**
   * 保存処理
   * @param data userModel
   * @returns userModel
   */
  const tempSave = async (data: UserModel): Promise<void> => {
    const result = await saveTempUser(data);

    if (result.status === StatusCode.Accepted) {
      await showMessage(t('user.message.このメールアドレスは既に登録されてます。'));
      return;
    }

    // その他、共通処理
    subsequentApiProcess(result.status, async () => {
      // 通信成功
      setValue('userId', result.data?.userId as number);
      setValue('updateDateTime', result.data?.updateDateTime as Date);
      setValue('version', result.data?.version as number);
      if (
        await showMessage(
          t(`signIn.message.仮登録が完了しました。メールをご確認の上、本登録を完了してください。`)
        )
      ) {
        console.log(`tempSave complate`);
        if (props.userType === UserType.farmer) {
          navigate(Path.farmerSignin);
        } else {
          navigate(Path.buyerSignin);
        }
      }
    });
  };

  return {
    t,
    avatar,
    control,
    errors,
    register,
    handleSubmit,
    getTitle,
    imageChange,
    onYasaiClick,
    onSaveClick,
    onSigninClick,
    onZipClick,
  };
};

export default useUser;

import { useEffect, useState, useCallback } from 'react';

import { getCropsList, getCropsListByCropsName } from 'api/crops';
import { getCultivationList } from 'api/cultivation';
import { getUntreatedCount } from 'api/purchase';
import { getSalesList } from 'api/sales';
import { Path } from 'enum';
import useCommon from 'features/useCommon';
import CropsModel from 'models/crops';
import CultivationModel from 'models/cultivation';
import SalesModel from 'models/sales';

/**
 * CropsListのカスタムフック
 * @returns cropsListで使用するカスタムフック
 */
const useCropsList = () => {
  // 共通カスタムフック
  const { t, navigate, subsequentApiProcess, loading } = useCommon();
  // 作物情報リスト
  const [cropsModelList, setCropsModelList] = useState<CropsModel[]>();
  // 栽培情報リスト
  const [cultivationModelList, setCultivationModelList] = useState<CultivationModel[]>();
  // 販売情報リスト
  const [salesModelList, setSalesModelList] = useState<SalesModel[]>();
  // 販売希望の未処理件数
  const [untreatedCount, setUntreatedCount] = useState<number | undefined>();

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * 作物一覧で取得するデータを取得する
   */
  const fetchData = useCallback(async () => {
    loading(true);
    await Promise.all([
      fetchCropsModelList(''),
      fetchCultivationModelList(),
      fetchSalesModelList(),
      fetchUntreatedCount(),
    ]);
    loading(false);
  }, []);

  /**
   * 作物情報を取得する
   * @returns Promise
   */
  const fetchCropsModelList = useCallback(async (searchText: string) => {
    if (searchText == null || searchText.trim() === '') {
      const result = await getCropsList();
      setCropsModelList(result.data as CropsModel[]);
    } else {
      const result = await getCropsListByCropsName(searchText);
      setCropsModelList(result.data as CropsModel[]);
    }
  }, []);

  /**
   * 栽培情報を取得する
   * @returns Promise
   */
  const fetchCultivationModelList = useCallback(async () => {
    const result = await getCultivationList();
    subsequentApiProcess(result.status, () => {
      setCultivationModelList(result.data as CultivationModel[]);
    });
  }, []);

  /**
   * 販売情報を取得する
   * @returns Promise
   */
  const fetchSalesModelList = useCallback(async () => {
    const result = await getSalesList();
    subsequentApiProcess(result.status, () => {
      setSalesModelList(result.data as SalesModel[]);
    });
  }, []);

  /**
   * 販売希望の未処理件数を取得する
   */
  const fetchUntreatedCount = useCallback(async () => {
    const result = await getUntreatedCount();
    subsequentApiProcess(result.status, () => {
      if (result.data?.count !== 0) {
        setUntreatedCount(result.data?.count);
      }
    });
  }, []);

  /**
   * 作物IDから栽培情報を取得する
   * @param cropsId 作物ID
   * @returns 栽培情報
   */
  const getCultivationModelList = (cropsId: number): CultivationModel[] => {
    return cultivationModelList?.filter((x) => x.cropsId === cropsId) ?? [];
  };

  /**
   * 作物IDから販売情報を取得する
   * @param cropsId 作物ID
   * @returns 販売情報
   */
  const getSalesModelList = (cropsId: number): SalesModel[] => {
    return salesModelList?.filter((x) => x.cropsId === cropsId) ?? [];
  };

  /**
   * 野菜の追加をクリック
   * @param e event
   */
  const onAddCropsClick = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`addVegetableClick start e: ${e}`);
    // やさい情報へ遷移する
    navigate(Path.crops, { state: { cropsId: 0 } });
    console.debug(`addVegetableClick end`);
  }, []);

  /**
   * 購入希望者をクリック
   * @param e event
   */
  const onPotentialBuyerClick = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`purchaserClick start e: ${e}`);
    // やさい情報へ遷移する
    navigate(Path.potentialBuyerList);
    console.debug(`purchaserClick end`);
  }, []);

  /**
   * 農家情報をクリック
   * @param e event
   */
  const onFarmerClick = useCallback((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`farmerClick start e: ${e}`);
    // 農家情報へ遷移する
    navigate(Path.farmerUpdate);
    console.debug(`farmerClick end`);
  }, []);

  /**
   * 作物名で絞り込みを実施する
   * @param searchText 検索条件
   */
  const onSearchClick = useCallback(async (e: { searchText: string }): Promise<void> => {
    console.debug(`onSearchClick start ${JSON.stringify(e.searchText)}`);
    await fetchCropsModelList(e.searchText.trim());
  }, []);

  return {
    t,
    cropsModelList,
    getCultivationModelList,
    getSalesModelList,
    untreatedCount,
    onAddCropsClick,
    onPotentialBuyerClick,
    onFarmerClick,
    onSearchClick,
  };
};

export default useCropsList;

import React from 'react';

import { Card, CardMedia, CardContent, Typography, CardActionArea, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ScheduleModel from 'models/schedule';
import CommonUtils from 'utils/commonUtils';

/**
 * 販売予定のコンポーネント
 */
const Schedule = (props: { scheduleModel: ScheduleModel }) => {
  return (
    <Card sx={{ m: 1 }}>
      <Grid container>
        <CardActionArea>
          <Box sx={{ position: 'relative' }}>
            <CardMedia
              component="img"
              height="160"
              image={CommonUtils.base64ImageSrc(props.scheduleModel.cropsImage)}
            />
          </Box>
          <CardContent>
            {/* 作物名 */}
            <Grid xs={12}>
              <Typography
                sx={{ minHeight: 25, maxHeight: 25 }}
                variant="h4"
                overflow="hidden"
                gutterBottom
              >
                {props.scheduleModel.cropsName}
              </Typography>
            </Grid>
            {/* 農家名 */}
            <Grid xs={12}>
              <Typography sx={{ minHeight: 23, maxHeight: 23 }} variant="h5" overflow="hidden">
                {props.scheduleModel.userName}
              </Typography>
            </Grid>
            {/* 予定日 */}
            <Grid xs={12}>
              <Typography
                variant={'h5'}
                sx={{ minHeight: 23, maxHeight: 23 }}
                textAlign={'right'}
                gutterBottom
              >
                {CommonUtils.dateFormat(props.scheduleModel.salesScheduleDate)}
              </Typography>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Grid>
    </Card>
  );
};

export default Schedule;

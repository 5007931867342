import * as React from 'react';
import { Bars } from 'react-loader-spinner';

import Backdrop from '@mui/material/Backdrop';

/**
 * LoadingProps
 */
interface LoadingProps {
  /**
   * open
   * true: 開く、false: 閉じる
   */
  open: boolean;
}

const Loading: React.FC<LoadingProps> = (props) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={props.open}>
      <Bars color="#fff" height={60} width={60} />
    </Backdrop>
  );
};

export default Loading;

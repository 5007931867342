import { useEffect, useState } from 'react';

import { savePurchase } from 'api/purchase';
import { getShopItem, getShopList } from 'api/sales';
import { DialogType } from 'enum';
import { Path, StatusCode } from 'enum';
import useCommon from 'features/useCommon';
import { initializePurchaseModel } from 'models/purchase';
import ShopModel, { initializeShopModel } from 'models/shop';
import CommonUtils from 'utils/commonUtils';

import { OrderForm, initializeOrderForm } from './components/useOrder';

/**
 * ShopItemのカスタムフック
 * @returns
 */
const useShopItem = () => {
  // 共通のカスタムフック
  const { t, navigate, showMessage, useParams, enqueueSnackbar, loading } = useCommon();
  // 画面間パラメータ
  const { paramUserId, paramSalesId } = useParams();
  // パラメータを設定する
  const [userId, setUserId] = useState(Number(paramUserId));
  const [salesId, setSalesId] = useState(Number(paramSalesId));
  // 対象の商品
  const [shopModel, setShopModel] = useState<ShopModel>(initializeShopModel());
  // 農家が作っている商品
  const [shopModelList, setShopModelList] = useState<ShopModel[]>();
  // 入力フォーム
  const [order, setOrder] = useState<OrderForm>(initializeOrderForm());

  // 販売IDが変更したらリロード
  useEffect(() => {
    console.log(`useEffect start`);
    Promise.all([fetchData()]);
  }, [salesId]);

  /**
   * 初期データを取得する
   */
  const fetchData = async (): Promise<void> => {
    loading(true);
    await Promise.all([fetchShopModel(), fetchShopModelList()]);
    loading(false);
  };

  /**
   * 商品情報を取得する
   */
  const fetchShopModel = async (): Promise<void> => {
    const result = await getShopItem(salesId);
    setShopModel(result.data as ShopModel);
    console.log(shopModel);
  };

  /**
   * 商品販売者のその他の商品
   */
  const fetchShopModelList = async (): Promise<void> => {
    const result = await getShopList(userId);
    let data = result.data as ShopModel[];
    // 表示中のデータだけ削る
    data = data.filter((x) => x.salesId !== salesId);
    setShopModelList(data);
    console.log(shopModelList);
  };

  /**
   * 販売中のカードクリック処理
   * @param e カードイベント
   */
  const onCardClick = (e: { userId: number; salesId: number }): void => {
    console.log(`onCardClick start ${e}`);
    setUserId(e.userId);
    setSalesId(e.salesId);
    console.log(`onCardClick end`);
  };

  /**
   * 購入希望クリック処理
   * @param e 購入希望ボタンクリック処理
   */
  const onPurchaseClick = async (e: {
    desiredDeliveryDate: Date;
    orderNumber: number;
    memo: string;
  }): Promise<void> => {
    console.log(`onPurchaseClick start ${JSON.stringify(e)}`);

    // ログインしてない場合、ログイン画面へ遷移する
    if (!(await CommonUtils.isLoginBuyer())) {
      navigate(Path.buyerSignin);
      loading(false);
      return;
    }

    if (
      await showMessage(t(`shopItem.message.注文を送信してもよろしいですか？`), DialogType.OkCancel)
    ) {
      // ローディングの開始
      loading(true);
      // 残在庫＞＝注文数
      if (!canBuy(e.orderNumber)) {
        await showMessage(t(`shopItem.message.注文数は在庫数より少なくしてください。`));
        loading(false);
        return;
      }

      // 注文送信
      if (await createPurchase(e.desiredDeliveryDate, e.orderNumber, e.memo)) {
        enqueueSnackbar(
          t(`shopItem.message.メールを送信しました。販売元からの返信をお待ちください。`),
          { variant: 'success' }
        );
      } else {
        await showMessage(
          t(`common.message.システムエラーが発生しました。管理者に連絡してください。`)
        );
      }
      // データを再取得する
      await fetchData();

      // 入力のクリア
      setOrder(initializeOrderForm());

      loading(false);
    }

    console.log(`onPurchaseClick end`);
  };

  /**
   * 購入希望を作成する
   * @param desiredDeliveryDate 購入希望日
   * @param orderNumber 注文数
   * @param memo メモ
   */
  const createPurchase = async (desiredDeliveryDate: Date, orderNumber: number, memo: string) => {
    // モデルを生成
    const model = initializePurchaseModel(salesId, desiredDeliveryDate, orderNumber, memo);
    const result = await savePurchase(model);
    if (result.status === StatusCode.Success) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * 購入可能かチェックする
   * @returns true: 購入できる、false: 購入できない
   */
  const canBuy = async (orderNumber: number) => {
    const result = await getShopItem(salesId);
    if (result.status !== StatusCode.Success) {
      return false;
    }
    const shop = result.data as ShopModel;
    setShopModel(shop);
    if (shop.remainingStock >= orderNumber) {
      return true;
    }
    return false;
  };

  return {
    t,
    shopModel,
    shopModelList,
    order,
    onCardClick,
    onPurchaseClick,
  };
};

export default useShopItem;

import * as React from 'react';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import { FaKey } from 'react-icons/fa';

import usePassword from './usePassword';

/**
 * パスワードを変更するページ
 * @returns
 */
const Password = () => {
  const { t, onSubmit, register, handleSubmit, errors } = usePassword();

  return (
    <motion.div
      initial={{ opacity: 0 }} // 初期状態
      animate={{ opacity: 1 }} // マウント時
      exit={{ opacity: 0 }} // アンマウント時
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('password.label.パスワードの変更')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              {...register('password')}
              error={'password' in errors}
              helperText={errors.password?.message}
              label={t('password.label.パスワード')}
              type="password"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaKey />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              {...register('comfirmPassword')}
              error={'comfirmPassword' in errors}
              helperText={errors.comfirmPassword?.message}
              label={t('password.label.パスワードの確認')}
              type="password"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaKey />
                  </InputAdornment>
                ),
              }}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t('password.label.パスワードを変更する')}
            </Button>
          </Box>
        </Box>
      </Container>
    </motion.div>
  );
};

export default Password;

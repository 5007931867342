/**
 * 栽培モデル
 */
export default interface CultivationModel {
  /**
   * 栽培キー（主キー）
   */
  cultivationId: number;
  /**
   * 農家キー
   */
  userId: number;
  /**
   * 作物キー
   */
  cropsId: number;
  /**
   * ファイルイメージ
   */
  cultivationImageFile?: File;
  /**
   * ファイルイメージ（文字列）
   */
  cultivationImage?: string;
  /**
   * 作業日
   */
  workingDate: Date;
  /**
   * 作業区分
   */
  workType: string;
  /**
   * 作業時間
   */
  workTime: string;
  /**
   * 作業内容
   */
  memo: string;
  /**
   * 公開する
   */
  isPublish: boolean;
  /**
   * 更新日時
   */
  updateDateTime: Date;
  /**
   * バージョン
   */
  version: number;
}

/**
 * 栽培モデルの初期値を取得する
 * @param cropsId 作物ID
 * @returns 栽培モデル
 */
export const initializeCultivationModel = (cropsId: number): CultivationModel => {
  return {
    cultivationId: 0,
    userId: 0,
    cropsId: cropsId,
    cultivationImageFile: undefined,
    cultivationImage: undefined,
    workingDate: new Date(),
    workType: '',
    workTime: '',
    memo: '',
    isPublish: true,
    updateDateTime: new Date(),
    version: 0,
  };
};

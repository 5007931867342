import { useEffect, useState } from 'react';

import { getPurchaseList, savePurchase } from 'api/purchase';
import { getPurchaseHistorySalesList } from 'api/sales';
import { DialogType } from 'enum';
import { Path, PurchaseType } from 'enum';
import useCommon from 'features/useCommon';
import PurchaseModel from 'models/purchase';
import SalesModel, { initializeSalesModel } from 'models/sales';
import CommonUtils from 'utils/commonUtils';

/**
 * PurhcaseHistoryのカスタムフック
 * @returns クロージャ
 */
const usePurchaseHistory = () => {
  const { t, navigate, subsequentApiProcess, showMessage, enqueueSnackbar, loading } = useCommon();

  const [purchaseModelList, setPurchaseModelList] = useState<PurchaseModel[]>(
    new Array<PurchaseModel>()
  );
  const [salesModelList, setSalesModelList] = useState<SalesModel[]>(new Array<SalesModel>());

  // 初期データ取得
  useEffect(() => {
    console.log(`useEffect start`);
    fetchData();
    console.log(`useEffect end`);
  }, []);

  /**
   * 初期データ取得
   */
  const fetchData = async () => {
    console.log(`fetchData start`);
    loading(true);
    await Promise.all([fetchPurchaseList(), fetchSalesList()]);
    loading(false);
    console.log(`fetchData end`);
  };

  /**
   * 購入希望リスト
   */
  const fetchPurchaseList = async () => {
    console.log(`fetchPurchase start `);
    const result = await getPurchaseList();
    subsequentApiProcess(result.status, () => {
      setPurchaseModelList(result.data as PurchaseModel[]);
    });
    console.log(`fetchPurchase end`);
  };

  /**
   * 購入希望商品リスト
   */
  const fetchSalesList = async () => {
    console.log(`fetchSales start `);
    const result = await getPurchaseHistorySalesList();
    subsequentApiProcess(result.status, () => {
      setSalesModelList(result.data as SalesModel[]);
    });
    console.log(`fetchSales end`);
  };

  /**
   * 販売モデル
   * @param salesId 販売ID
   * @returns 販売モデル
   */
  const getSalesModel = (salesId: number): SalesModel => {
    console.log(`getSales start`);
    // 値が取れない場合は存在しないが、undefined が戻らないようにする
    return salesModelList.find((x) => x.salesId === salesId) ?? initializeSalesModel();
  };

  /**
   * 購入商品をキャンセルする
   * @param purchaseId 購入ID
   */
  const onCancelClick = async (e: { purchaseId: number }) => {
    console.log(`onCancelClick start purchaseId: ${e.purchaseId}`);
    if (await showMessage(t(`purchaseHistory.message.キャンセルしますか？`), DialogType.OkCancel)) {
      cancel(e.purchaseId);
    }
    console.log(`onCancelClick end`);
  };

  /**
   * Imageクリック
   * @param userId ユーザーID
   * @param salesId 販売ID
   */
  const onImageClick = (e: { userId: number; salesId: number }) => {
    console.log(`onImageClick start userId: ${e.userId} salesId: ${e.salesId}`);
    navigate(`${Path.shopItem}/${e.userId}/${e.salesId}`);
    console.log(`onImageClick end`);
  };

  /**
   * 購入をキャンセルする
   * @param purchaseId 購入ID
   */
  const cancel = async (purchaseId: number) => {
    console.log(`cancel start purchaseId: ${purchaseId}`);
    loading(true);
    const purchaseModel = purchaseModelList.find((x) => x.purchaseId === purchaseId);
    if (purchaseModel === undefined) {
      loading(false);
      return;
    }
    const updateModel = CommonUtils.deepCopy(purchaseModel);
    updateModel.purchaseType = PurchaseType.cancel;
    const result = await savePurchase(updateModel);
    subsequentApiProcess(result.status, async () => {
      purchaseModel.purchaseType = PurchaseType.cancel;
      enqueueSnackbar(t('purchaseHistory.message.キャンセルしました。'), { variant: 'success' });
      loading(false);
    });
  };

  return {
    t,
    purchaseModelList,
    getSalesModel,
    onCancelClick,
    onImageClick,
  };
};

export default usePurchaseHistory;

import { useDispatch } from 'react-redux';

import { insertUser, getUser } from 'api/user';
import { Path, StatusCode, UserType } from 'enum';
import useCommon from 'features/useCommon';
import UserModel from 'models/user';
import { setLoginUser } from 'slices/loginUserSlice';
import { setToken } from 'slices/tokenSlice';

/**
 * TemporaryRegistrationのカスタムフック
 * @returns
 */
const useTemporaryRegistration = () => {
  // redux dispath
  const dispatch = useDispatch();
  // 共通フック
  const { t, navigate, location, showMessage, subsequentApiProcess, loading, enqueueSnackbar } =
    useCommon();

  // url パラメータを取得する
  const search = location.search;
  const query = new URLSearchParams(search);
  const temp = query.get('temp') ?? '';

  /**
   * 本登録ボタンクリック
   * @param { React.MouseEvent } e
   */
  const onRegistClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log(`registClick start ${e}`);
    // api でデータの取得
    loading(true);

    const result = await insertUser(temp);
    switch (result.status) {
      case StatusCode.Accepted:
        await showMessage(
          t('temporaryRegistration.message.有効期限がきれているため、再度、仮登録してください。')
        );
        return;
      case StatusCode.AlreadyReported:
        await showMessage(t('temporaryRegistration.message.ユーザーは既に登録済みです。'));
        return;
    }
    subsequentApiProcess(result.status, async () => {
      const token = result.data?.token as string;
      // トークンの格納
      dispatch(setToken(token));
      sessionStorage.setItem('token', token);
      enqueueSnackbar(t('temporaryRegistration.message.本登録が完了しました。'), {
        variant: 'success',
      });
      // 次画面に遷移する
      await showNextScreen();
    });
    loading(false);
  };

  /**
   * 次画面に遷移する
   */
  const showNextScreen = async () => {
    const result = await getUser();
    subsequentApiProcess(result.status, () => {
      const userModel = result.data as UserModel;
      // reduxでデータ保持
      dispatch(setLoginUser(userModel));
      if (userModel.userType === UserType.farmer) {
        // 農家のログイン後のURL
        navigate(Path.cropsList);
      } else {
        // 購入者のログイン後のURL
        navigate(Path.shop);
      }
    });
  };

  return {
    t,
    onRegistClick,
  };
};

export default useTemporaryRegistration;

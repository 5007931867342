import React from 'react';

import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import NavigateMotion from 'components/navigateMotion';
import ShopMenuBar from 'components/shopMenuBar';

import PurchaseHistoryItem from './components/purchaseHistoryItem';
import usePurchaseHistory from './usePurchaseHistory';

/**
 * 購入履歴ページ
 * @returns
 */
const PurchaseHistory = () => {
  // カスタムフック
  const { purchaseModelList, getSalesModel, onImageClick, onCancelClick } = usePurchaseHistory();
  return (
    <React.Fragment>
      <ShopMenuBar />
      <NavigateMotion>
        <Container>
          {purchaseModelList.length > 0 &&
            purchaseModelList.map((purchaseModel) => {
              return (
                <PurchaseHistoryItem
                  key={purchaseModel.purchaseId}
                  purchaseModel={purchaseModel}
                  salesModel={getSalesModel(purchaseModel.salesId)}
                  onCancelClick={onCancelClick}
                  onImageClick={onImageClick}
                />
              );
            })}
          <Grid container sx={{ p: 2, m: 2 }}></Grid>
        </Container>
      </NavigateMotion>
    </React.Fragment>
  );
};

export default PurchaseHistory;

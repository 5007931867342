import { DialogType, Path } from 'enum';
import useCommon from 'features/useCommon';
import StorageUtils from 'utils/storageUtils';

/**
 * メニューバーのカスタムフック
 * @returns
 */
const useMenuBar = () => {
  // 共通カスタムフック
  const { t, navigate, showMessage, enqueueSnackbar, location } = useCommon();

  /**
   * サインアウトボタンクリック
   * @param e event
   */
  const onSignOutCilck = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log(`onSingOutClick start ${e}`);
    if (await showMessage(t(`menuBar.message.ログアウトしますか？`), DialogType.OkCancel)) {
      StorageUtils.removeToken();
      enqueueSnackbar(t('menuBar.message.ログアウトしました。'), { variant: 'success' });
      navigate(Path.farmerSignin);
    }
  };

  return {
    onSignOutCilck,
    location,
  };
};

export default useMenuBar;

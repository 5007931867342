import React from 'react';

import useCommon from 'features/useCommon';
import ShopModel from 'models/shop';

/**
 * ShopItemのカスタムフック
 * @returns
 */
export const useItem = (props: {
  shopModel: ShopModel;
  onClick?: (e: { userId: number; salesId: number }) => void;
}) => {
  const { t } = useCommon();
  /**
   * 商品のクリック
   */
  const onItemClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    console.log(`onItemClick e:${e}`);
    // クリックイベントの通知
    if (props.onClick !== undefined) {
      props.onClick({ userId: props.shopModel.userId, salesId: props.shopModel.salesId });
    }
  };

  return {
    t,
    onItemClick,
  };
};

/**
 * 販売のインターフェス
 */
export default interface SalesModel {
  /**
   * 販売キー（主キー）
   */
  salesId: number;
  /**
   * ユーザーキー（農家）
   */
  userId: number;
  /**
   * 作物キー
   */
  cropsId: number;
  /**
   * ファイルイメージ
   */
  salesImageFile?: File;
  /**
   * ファイルイメージ（文字列）
   */
  salesImage?: string;
  /**
   * 名前
   */
  salesName: string;
  /**
   * 販売日
   */
  salesDate: Date;
  /**
   * 販売金額
   */
  amount: number;
  /**
   * 入り数
   */
  quantity: number;
  /**
   * 販売単位
   */
  unit: string;
  /**
   * 注文可能数
   */
  stock: number;
  /**
   * 販売方法
   */
  memo: string;
  /**
   * 公開する
   */
  isPublish: boolean;
  /**
   * 閲覧者件数
   */
  viewerCount: number;
  /**
   * 更新日時
   */
  updateDateTime: Date;
  /**
   * バージョン
   */
  version: number;
}

/**
 * 販売モデルの初期値を取得する
 * @param cropsId 作物ID
 * @returns 販売モデル
 */
export const initializeSalesModel = (cropsId = 0): SalesModel => {
  return {
    salesId: 0,
    userId: 0,
    cropsId: cropsId,
    salesImageFile: undefined,
    salesImage: undefined,
    salesName: '',
    salesDate: new Date(),
    amount: 0,
    quantity: 0,
    unit: '',
    stock: 0,
    memo: '',
    isPublish: false,
    viewerCount: 0,
    updateDateTime: new Date(),
    version: 0,
  };
};

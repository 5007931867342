import React, { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { getCultivation, removeCultivation, saveCultivation } from 'api/cultivation';
import { DialogType } from 'enum';
import { Path } from 'enum';
import useCommon from 'features/useCommon';
import CultivationModel, { initializeCultivationModel } from 'models/cultivation';
import * as yup from 'yup';

/**
 * Cultivationのカスタムフック
 * @returns
 */
const useCultivation = () => {
  // imageBase64
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  // 共通のカスタムフック
  const { t, navigate, location, showMessage, subsequentApiProcess, enqueueSnackbar, loading } =
    useCommon();
  // 画面間パラメータの取得
  const cropsId = location.state.cropsId as number;
  const cultivationId = (location.state.cultivationId as number) ?? 0;
  // 新規フラグ
  const [isNew, setIsNew] = useState(true);

  // バリデーションルール
  const schema = yup.object().shape({
    workType: yup.string().required(t('cultivation.message.作業区分を入力してください。')),
    workTime: yup.string().required(t('cultivation.message.作業時間を入力してください。')),
    memo: yup.string(),
  });

  // useFormの設定
  const {
    control,
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<CultivationModel>({
    resolver: yupResolver(schema),
    defaultValues: async () => await fetchCultivation(),
  });

  /**
   * 栽培情報を取得する
   */
  const fetchCultivation = async (): Promise<CultivationModel> => {
    loading(true);
    let cultivationModel = initializeCultivationModel(cropsId);
    if (cultivationId !== 0) {
      const result = await getCultivation(cultivationId);
      subsequentApiProcess(result.status, () => {
        // 正常処理
        cultivationModel = result.data as CultivationModel;
        if (cultivationModel.cultivationImage) {
          setAvatar(cultivationModel.cultivationImage);
        }
      });
      setIsNew(false);
    }
    loading(false);
    console.log(`fetchCultivation ${JSON.stringify(cultivationModel)}`);
    return cultivationModel;
  };

  /**
   * 作業区分のChipクリックイベント
   * @param label 作業区分
   */
  const workTypeChipClick = (label: string) => {
    console.debug(`workTypeChipClick start`);
    resetField('workType');
    setValue('workType', label);
    console.debug(`workTypeChipClick end`);
  };

  /**
   * 作業時間のChipクリックイベント
   * @param label 作業時間
   */
  const workTimeChipClick = (label: string) => {
    console.debug(`workTimeChipClick start`);
    resetField('workTime');
    setValue('workTime', label);
    console.debug(`workTimeChipClick end`);
  };

  /**
   * アバターのチェンジイベント
   * @param e event
   */
  const imageChange = useCallback(async (e: { file: File; file64: string }) => {
    console.debug(`imageChange start ${e}`);
    setValue('cultivationImage', e.file64);
    console.debug(`imageChange end`);
  }, []);

  /**
   * 保存をクリック
   * @param e event
   */
  const onSaveClick: SubmitHandler<CultivationModel> = async (data) => {
    console.log(`onSaveClick start e: ${JSON.stringify(data)}`);
    loading(true);
    await save(data);
    loading(false);
    console.log(`onSaveClick end`);
  };

  /**
   * 野菜一覧をクリック
   * @param e event
   */
  const onYasaiClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`onYasaiClick start e: ${e}`);
    reset();
    // 野菜一覧へ遷移する
    navigate(Path.cropsList);
    console.debug(`onYasaiClick end`);
  };

  /**
   * 野菜一覧をクリック
   * @param e event
   */
  const onDeleteClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> => {
    console.debug(`onDeleteClick start e: ${e}`);
    if (await showMessage(t(`common.message.削除してもよろしいですか？`), DialogType.OkCancel)) {
      remove();
    }
    console.debug(`onDeleteClick end`);
  };

  /**
   * 新規登録処理
   * @param data cultivationModel
   */
  const save = async (data: CultivationModel): Promise<void> => {
    const result = await saveCultivation(data);
    subsequentApiProcess(result.status, () => {
      // 更新データの格納
      if (data.cultivationId === 0) {
        setValue('userId', result.data?.userId as number);
        setValue('cultivationId', result.data?.cultivationId as number);
      }
      setValue('updateDateTime', result.data?.updateDateTime as Date);
      setValue('version', result.data?.version as number);
      // メッセージの格納
      enqueueSnackbar(t(`common.message.保存しました。`), { variant: 'success' });
      setIsNew(false);
    });
  };

  /**
   * 削除処理
   */
  const remove = async () => {
    console.log(`remove start`);
    loading(true);

    const cultivationId = getValues('cultivationId');
    const version = getValues('version');
    const result = await removeCultivation(cultivationId, version);
    subsequentApiProcess(result.status, () => {
      enqueueSnackbar(t('common.message.削除しました。'), { variant: 'success' });
      navigate(Path.cropsList);
    });

    loading(false);
    console.log(`remove end`);
  };

  return {
    t,
    avatar,
    control,
    errors,
    register,
    handleSubmit,
    isNew,
    workTypeChipClick,
    workTimeChipClick,
    imageChange,
    onYasaiClick,
    onSaveClick,
    onDeleteClick,
  };
};

export default useCultivation;

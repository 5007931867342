import { PurchaseType } from 'enum';
import useCommon from 'features/useCommon';
import PurchaseModel from 'models/purchase';
import SalesModel from 'models/sales';

/**
 * 購入履歴のカスタムフック
 */
const usePurchaseHistoryItem = (props: {
  purchaseModel: PurchaseModel;
  salesModel: SalesModel;
  onCancelClick: (e: { purchaseId: number }) => void;
  onImageClick: (e: { userId: number; salesId: number }) => void;
}) => {
  const { t } = useCommon();

  /**
   * Imageクリックイベント
   * @param e event
   */
  const onImageClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    console.log(`onImageClick start ${e}`);
    props.onImageClick({ userId: props.salesModel.userId, salesId: props.salesModel.salesId });
  };
  /**
   * Cancelボタンクリックイベント
   * @param e event
   */
  const onCancelClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    console.log(`onCancelClick start ${e}`);
    props.onCancelClick({ purchaseId: props.purchaseModel.purchaseId });
  };
  /**
   * 購入区分名を取得する
   * @param purchaseType 購入区分
   */
  const getPurchaseTypeName = (purchaseType: PurchaseType): string => {
    switch (purchaseType) {
      case PurchaseType.confirm:
        return t(`purchaseHistory.label.確定しました`);
      case PurchaseType.decline:
        return t(`purchaseHistory.label.お店からキャンセルされました`);
      case PurchaseType.cancel:
        return t(`purchaseHistory.label.キャンセルしました`);
      default:
        return t(`purchaseHistory.label.確定されていません`);
    }
  };
  /**
   * 購入区分の色を取得する
   * @param purchaseType 購入区分
   * @returns: 色
   */
  const getPurchaseColor = (purchaseType: PurchaseType): string => {
    switch (purchaseType) {
      case PurchaseType.confirm:
        return '#2e7d32';
      case PurchaseType.decline:
        return '#d32f2f';
      case PurchaseType.cancel:
        return '#d32f2f';
      default:
        return '#0288d1';
    }
  };
  return {
    t,
    onImageClick,
    onCancelClick,
    getPurchaseTypeName,
    getPurchaseColor,
  };
};

export default usePurchaseHistoryItem;

import useCommon from 'features/useCommon';
import CommonUtils from 'utils/commonUtils';

/**
 * カレンダーテキストのカスタムフック
 * @returns
 */
const useCalendarTextFiled = () => {
  // 祝日モデルを取得
  const { nationalHolidayModelList } = useCommon();
  /**
   * 日付の色を設定する
   * @param date 日付
   * @returns スタイル
   */
  const switchDayColor = (date: Date) => {
    switch (date.getDay()) {
      case 0:
        return { color: 'red' };
      case 6:
        return { color: 'blue' };
      default:
        if (
          nationalHolidayModelList.some((x) => {
            const date1 = new Date(x.holiday);
            return CommonUtils.isSameDay(date1, date);
          })
        ) {
          return { color: 'red' };
        } else {
          return {};
        }
    }
  };

  return { switchDayColor };
};

export default useCalendarTextFiled;

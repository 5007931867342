import React from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { faBars, faCarrot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BottomNavigationAction, Container, InputAdornment, Stack, TextField } from '@mui/material';
import AvatarFileUpload from 'components/avatarFileUpload';
import BottomAppBar from 'components/bottomAppBar';
import CalendarTextField from 'components/calendarTextField';
import MenuAppBar from 'components/menuBar';
import NavigateMotion from 'components/navigateMotion';
import { BsFillLayersFill } from 'react-icons/bs';
import { FaSave } from 'react-icons/fa';
import { GiTomato } from 'react-icons/gi';
import { MdDelete } from 'react-icons/md';

import useCrops from './useCrops';

/**
 * 農作物情報ページ
 * @returns
 */
const Crops: React.FC = () => {
  const {
    t,
    avatar,
    control,
    errors,
    register,
    handleSubmit,
    isNew,
    imageChange,
    onYasaiClick,
    onSaveClick,
    onDeleteClick,
  } = useCrops();

  return (
    <React.Fragment>
      <MenuAppBar title={t('crops.title')}></MenuAppBar>
      <Container maxWidth={'sm'} component="form" onSubmit={handleSubmit(onSaveClick)}>
        <NavigateMotion>
          {/** ----------
             * 写真
             ---------- */}
          <AvatarFileUpload base64Image={avatar} onChange={imageChange} />
          <Stack spacing={3}>
            {/** ----------
             * 名前
             ---------- */}
            <TextField
              {...register('cropsName')}
              error={'cropsName' in errors}
              helperText={errors.cropsName?.message}
              label={t('crops.label.名前')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <GiTomato />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * 品種
             ---------- */}
            <TextField
              {...register('variety')}
              error={'variety' in errors}
              helperText={errors.variety?.message}
              label={t('crops.label.品種')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <GiTomato />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * 場所
             ---------- */}
            <TextField
              {...register('location')}
              error={'location' in errors}
              helperText={errors.location?.message}
              label={t('crops.label.栽培している場所')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={faBars} />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * 面積
             ---------- */}
            <Controller
              name="area"
              control={control}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <NumericFormat
                  type="tel"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onValueChange={(v) => onChange(v.floatValue)}
                  allowNegative={false}
                  thousandSeparator=","
                  customInput={TextField}
                  error={'area' in errors}
                  helperText={errors.area?.message}
                  label={t('crops.label.栽培面積（単位：a）')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BsFillLayersFill />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/** ----------
             * 販売予定日
             ---------- */}
            <CalendarTextField
              name="salesScheduleDate"
              control={control}
              label={t('crops.label.販売予定日')}
              error={'salesScheduleDate' in errors}
              helperText={errors.salesScheduleDate?.message}
            />
          </Stack>
        </NavigateMotion>
        <BottomAppBar>
          <BottomNavigationAction
            label={t('menu.やさい一覧')}
            icon={<FontAwesomeIcon icon={faCarrot} />}
            onClick={onYasaiClick}
          />
          <BottomNavigationAction
            label={t('menu.保存')}
            icon={<FaSave />}
            type="submit"
          ></BottomNavigationAction>
          {!isNew && (
            <BottomNavigationAction
              label={t('menu.削除')}
              icon={<MdDelete />}
              type="button"
              onClick={onDeleteClick}
            />
          )}
        </BottomAppBar>
      </Container>
    </React.Fragment>
  );
};

export default Crops;

import UserModel from 'models/user';

/**
 * ストレージ管理クラス
 */
export default class StorageUtils {
  /**
   * トークンを保持する
   * @param value 値
   */
  static setToken = (value: string): void => {
    sessionStorage.setItem('token', value);
  };
  /**
   * トークンを取得する
   * @returns トークン
   */
  static getToken = (): string | null => {
    return sessionStorage.getItem('token');
  };
  /**
   * トークンを削除する
   */
  static removeToken = (): void => {
    sessionStorage.removeItem('token');
  };
  /**
   * ユーザーを保持する
   * @param value 値
   */
  static setUser = (value: UserModel): void => {
    sessionStorage.setItem('user', JSON.stringify(value));
  };
  /**
   * ユーザーを取得する
   * @returns ユーザー
   */
  static getUser = (): UserModel | null => {
    const user = sessionStorage.getItem('user');
    if (user === null) {
      return null;
    }
    return JSON.parse(user);
  };
  /**
   * ユーザータイプを取得する
   * @returns ユーザータイプ
   */
  static getUserType = (): string | null => {
    const user = this.getUser();
    if (user === null) {
      return null;
    }
    return user.userType;
  };
  /**
   * ユーザーを削除する
   */
  static removeUser = (): void => {
    sessionStorage.removeItem('user');
  };
  /**
   * 全部のストレージを消す
   */
  static removeAll = (): void => {
    sessionStorage.clear();
  };
}

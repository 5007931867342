import * as React from 'react';

import { ArrowBack } from '@mui/icons-material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuBar from 'components/menuBar';
import ShopMenuBar from 'components/shopMenuBar';
import { UserType } from 'enum';
import { motion } from 'framer-motion';

import useSignIn from './useSignIn';

/**
 * ログインページ
 * @returns
 */
const SignIn = (props: { userType: UserType }) => {
  const {
    t,
    register,
    handleSubmit,
    errors,
    onSubmit,
    onCreateFarmerClick,
    onForgetPasswordClick,
    onShopBackClick,
  } = useSignIn(props);

  return (
    <motion.div
      initial={{ opacity: 0 }} // 初期状態
      animate={{ opacity: 1 }} // マウント時
      exit={{ opacity: 0 }} // アンマウント時
    >
      {props.userType === UserType.buyer && <ShopMenuBar />}
      {props.userType === UserType.farmer && (
        <>
          <MenuBar title={t('signIn.title.農家専用')}></MenuBar>
        </>
      )}
      <Button startIcon={<ArrowBack></ArrowBack>} sx={{ ml: 2 }} onClick={onShopBackClick}>
        {t('signIn.label.販売ページへ')}
      </Button>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('signIn.label.ログイン')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              label={t('signIn.label.メールアドレス')}
              autoComplete="email"
              {...register('email')}
              error={'email' in errors}
              helperText={errors.email?.message}
              autoFocus
            />
            <TextField
              margin="normal"
              fullWidth
              {...register('password')}
              error={'password' in errors}
              helperText={errors.password?.message}
              label={t('signIn.label.パスワード')}
              type="password"
              autoComplete="current-password"
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t('signIn.label.ログイン')}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={onCreateFarmerClick}>
                  {t('signIn.label.新規ユーザーを作成する')}
                </Link>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2" onClick={onForgetPasswordClick}>
                  {t('signIn.label.パスワードをお忘れの場合')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </motion.div>
  );
};

export default SignIn;

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppContext } from 'App';
import { DialogType, Path, StatusCode, UserType } from 'enum';
import { useSnackbar } from 'notistack';
import { useSelector } from 'store';
import StorageUtils from 'utils/storageUtils';

/**
 * 共通のカスタムフック
 * @returns
 */
const useCommon = () => {
  // 多言語化対応
  const { t } = useTranslation();
  // ページ遷移
  const navigate = useNavigate();
  const location = useLocation();
  // snackbar
  const { enqueueSnackbar } = useSnackbar();
  // redux
  const dispatch = useDispatch();
  // AppContextの戻り
  const appContext = useContext(AppContext);
  if (appContext === undefined) {
    throw new Error(`App Context is undefined`);
  }

  /**
   * APIの実行後の後続処理
   * @param statusCode ステータスコード
   * @param success 成功した時の後続処理
   * @param unauthorized 他のユーザーが更新してるときの後続処理
   */
  const subsequentApiProcess = async (statusCode: number, success?: () => void) => {
    switch (statusCode) {
      case StatusCode.Success:
        if (success) {
          await success();
        }
        break;
      case StatusCode.UnprocessableEntity:
        await showMessage(
          t(`common.message.他のユーザーによって変更されています。再表示してください。`)
        );
        break;
      case StatusCode.Unauthorized:
        await showMessage(
          t(`common.message.長時間操作されなかったため、再度ログインしてから実行してください。`)
        );
        if (StorageUtils.getUserType() === UserType.farmer) {
          navigate(Path.farmerSignin);
        } else {
          navigate(Path.buyerSignin);
        }
        break;
      default:
        await showMessage(
          t(`common.message.システムエラーが発生しました。管理者に連絡してください。`)
        );
        break;
    }
  };

  /**
   * メッセージダイアログを表示する
   * @param message メッセージ
   * @param dialogType ダイアログタイプ
   * @returns true: OK, flase: Cancel
   */
  const showMessage = (
    message: string,
    dialogType: DialogType = DialogType.OkOnly
  ): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
      appContext.setDialogConfig({
        message: message,
        dialogType: dialogType,
        resolve: resolve,
      });
    });
  };

  return {
    t,
    navigate,
    location,
    dispatch,
    useSelector,
    subsequentApiProcess,
    enqueueSnackbar,
    showMessage,
    useParams,
    loading: appContext.setLoadingOpen,
    nationalHolidayModelList: appContext.nationalHolidayModelList,
  };
};

export default useCommon;

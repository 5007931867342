import React from 'react';

import Grid from '@mui/material/Unstable_Grid2';
import ShopModel from 'models/shop';

import Item from './item';

/**
 * 商品の一覧を表示する
 * @returns
 */
const ItemList = (props: {
  shopModelList?: ShopModel[];
  onClick?: (e: { userId: number; salesId: number }) => void;
}) => {
  return (
    <Grid container>
      {props.shopModelList?.map((shopModel) => {
        return (
          <Grid key={shopModel.salesId} xs={6} sm={3} md={3} lg={2} xl={2}>
            <Item shopModel={shopModel} onClick={props.onClick} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ItemList;

import { PurchaseType } from 'enum';

/**
 * 購入モデル
 */
export default interface PurchaseModel {
  /**
   * 購入キー
   */
  purchaseId: number;
  /**
   * 販売キー
   */
  salesId: number;
  /**
   * 購入者キー
   */
  userId: number;
  /**
   * 購入希望数
   */
  purchaseCount: number;
  /**
   * 配達希望日
   */
  desiredDeliveryDate: Date;
  /**
   * 購入日時
   */
  purchaseDateTime: Date;
  /**
   * 購入区分
   */
  purchaseType: PurchaseType;
  /**
   * その他・意見
   */
  memo: string;
  /**
   * 更新日時
   */
  updateDateTime: Date;
  /**
   * バージョン
   */
  version: number;
}

/**
 * 購入希望モデルの初期値取得
 * @returns 購入希望モデル
 */
export const initializePurchaseModel = (
  salesId: number,
  desiredDeliveryDate?: Date,
  purchaseCount?: number,
  memo?: string
): PurchaseModel => {
  const model = {
    purchaseId: 0,
    salesId: salesId,
    userId: 0,
    purchaseCount: 0,
    desiredDeliveryDate: new Date(),
    memo: '',
    purchaseDateTime: new Date(),
    purchaseType: PurchaseType.untreated,
    updateDateTime: new Date(),
    version: 0,
  };

  if (desiredDeliveryDate !== undefined) {
    model.desiredDeliveryDate = desiredDeliveryDate;
  }

  if (purchaseCount !== undefined) {
    model.purchaseCount = purchaseCount;
  }

  if (memo !== undefined) {
    model.memo = memo;
  }

  return model;
};

import React from 'react';
import { PatternFormat } from 'react-number-format';

import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  TextField,
} from '@mui/material';
import { PurchaseType } from 'enum';
import PurchaseModel from 'models/purchase';
import SalesModel from 'models/sales';
import UserModel from 'models/user';
import { BsPinMapFill, BsFillTelephoneFill } from 'react-icons/bs';
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import CommonUtils from 'utils/commonUtils';

import usePotentialBuyerItem from './usePotentialBuyerItem';

/**
 * 購入希望ページ
 * @returns
 */
const PotentialBuyerItem = (props: {
  salesModel: SalesModel;
  purchaseModel: PurchaseModel;
  userModel?: UserModel;
  onPurchaseClick: (purchaseId: number) => void;
  onDeclineClick: (purchaseId: number) => void;
}) => {
  const { t, getPurchaseTypeName, getPurchaseTypeColor, getDefaultExpanded } =
    usePotentialBuyerItem();

  const CustomAvatar = () => {
    const color = getPurchaseTypeColor(props.purchaseModel.purchaseType);
    const name = getPurchaseTypeName(props.purchaseModel.purchaseType);
    return (
      /** ----------
       * 未確定／確定
       ---------- */
      <Avatar
        sx={{
          bgcolor: color,
          width: 35,
          height: 35,
        }}
        alt={name}
        src="_"
      />
    );
  };

  return (
    <Accordion defaultExpanded={getDefaultExpanded(props.purchaseModel.purchaseType)}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <List>
          <ListItem>
            <ListItemAvatar sx={{ mx: -1 }}>
              <CustomAvatar />
            </ListItemAvatar>
            {/** ----------
                * 購入希望者
                ---------- */}
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="div"
                    variant="body2"
                    color="text.primary"
                  >
                    <b>{props.userModel?.userName}</b>
                  </Typography>
                  <Typography
                    sx={{ ml: 2, display: 'inline' }}
                    component="div"
                    variant="body2"
                    color="text.primary"
                  >
                    {`${props.purchaseModel.purchaseCount.toLocaleString()} ${
                      props.salesModel.unit
                    }`}
                  </Typography>
                </React.Fragment>
              }
              secondary={
                <Typography
                  sx={{ display: 'inline' }}
                  component="div"
                  variant="body2"
                  color="text.primary"
                >
                  {'2023/02/03'}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={3} sx={{ ml: 2 }}>
          {/** ----------
            * 電話番号
            ---------- */}
          <TextField
            disabled={true}
            variant="standard"
            label={t('potentialBuyerList.label.電話番号')}
            value={props.userModel?.phoneNumber}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsFillTelephoneFill />
                </InputAdornment>
              ),
            }}
          />
          {/** ----------
            * 郵便番号
            ---------- */}
          <PatternFormat
            disabled={true}
            variant="standard"
            format="###-####"
            value={props.userModel?.postalCode}
            customInput={TextField}
            label={t('potentialBuyerList.label.郵便番号')}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">〒</InputAdornment>,
            }}
          />
          {/** ----------
              * 住所
              ---------- */}
          <TextField
            disabled={true}
            variant="standard"
            label={t('potentialBuyerList.label.住所')}
            multiline={true}
            value={`${CommonUtils.conbine(
              props.userModel?.prefecture,
              props.userModel?.municipality,
              props.userModel?.streetNumber,
              props.userModel?.building
            )}`}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BsPinMapFill />
                </InputAdornment>
              ),
            }}
          />
          {/** ----------
            * その他・要望
            ---------- */}
          <TextField
            disabled={true}
            variant="standard"
            label={t('potentialBuyerList.label.その他・要望')}
            multiline={true}
            value={`${props.purchaseModel.memo}`}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faFileLines} />
                </InputAdornment>
              ),
            }}
          />
          {props.purchaseModel.purchaseType === PurchaseType.untreated && (
            <>
              <Button
                variant="contained"
                color="success"
                startIcon={<GiConfirmed />}
                onClick={(_) => {
                  props.onPurchaseClick(props.purchaseModel.purchaseId);
                }}
              >
                {t('potentialBuyerList.label.注文を確定する')}
              </Button>
              <Button
                variant="outlined"
                color="error"
                startIcon={<GiCancel />}
                onClick={(_) => {
                  props.onDeclineClick(props.purchaseModel.purchaseId);
                }}
              >
                {t('potentialBuyerList.label.注文をお断りする')}
              </Button>
            </>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default PotentialBuyerItem;

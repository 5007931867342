/**
 * Dialogの種類
 */
export const DialogType = {
  /**
   * 非表示
   */
  DisplayNone: -1,
  /**
   * OkOnly
   */
  OkOnly: 0,
  /**
   * OkCancel
   */
  OkCancel: 1,
} as const;
export type DialogType = (typeof DialogType)[keyof typeof DialogType];

/**
 * ステータスコード
 */
export const StatusCode = {
  /**
   * 成功
   */
  Success: 200,
  /**
   * リクエストを受け取ったが、処理されていない
   */
  Accepted: 202,
  /**
   * 既に報告済みのリクエスト
   */
  AlreadyReported: 208,
  /**
   * 権限なし
   */
  Unauthorized: 401,
  /**
   * 期限切れ
   */
  NotAcceptable: 406,
  /**
   * 移動、または消滅している
   */
  Gone: 410,
  /**
   * 楽観ロックエラー
   */
  UnprocessableEntity: 422,
} as const;
export type StatusCode = (typeof StatusCode)[keyof typeof StatusCode];

/**
 * 購入状況
 */
export const PurchaseType = {
  /**
   * 購入希望の未処理
   */
  untreated: '00000',
  /**
   * 確定
   */
  confirm: '00001',
  /**
   * お断り
   */
  decline: '00002',
  /**
   * キャンセル
   */
  cancel: '00003',
} as const;
export type PurchaseType = (typeof PurchaseType)[keyof typeof PurchaseType];

/**
 * ユーザー種類
 */
export const UserType = {
  /**
   * 農家
   */
  farmer: '00001',
  /**
   * 購入者
   */
  buyer: '00002',
} as const;
export type UserType = (typeof UserType)[keyof typeof UserType];

/**
 * パス
 */
export const Path = {
  /**
   * ユーザーの本登録
   */
  userRegistration: '/userregistration',
  /**
   * 作物登録・更新
   */
  crops: '/farmercrops',
  /**
   * 作物一覧
   */
  cropsList: '/farmercropslist',
  /**
   * 栽培登録・更新
   */
  cultivation: '/farmercultivation',
  /**
   * 販売登録・更新
   */
  sales: '/farmersales',
  /**
   * 購入希望一覧
   */
  potentialBuyerList: '/farmer/potentialbuyerlist',
  /**
   * 農家・ログイン
   */
  farmerSignin: '/farmersignin',
  /**
   * 農家・登録
   */
  farmerCreate: '/farmernewuser',
  /**
   * 農家・更新
   */
  farmerUpdate: '/farmeruser',
  /**
   * 購入者・ログイン
   */
  buyerSignin: '/buyersignin',
  /**
   * 購入者・登録
   */
  buyerCreate: '/buyernewuser',
  /**
   * 購入者・更新
   */
  buyerUpdate: '/buyeruser',
  /**
   * 購入者・購入履歴
   */
  purchaseHistory: '/purchasehistory',
  /**
   * 販売一覧
   */
  shop: '/',
  /**
   * 農家・Email入力
   */
  farmerEmail: '/farmeremail',
  /**
   * 購入者・Email入力
   */
  buyerEmail: '/buyeremail',
  /**
   * パスワード
   */
  password: '/password',
  /**
   * 販売明細
   */
  shopItem: '/shopItem',
} as const;
export type Path = (typeof Path)[keyof typeof Path];

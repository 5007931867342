import { createSlice } from '@reduxjs/toolkit';
import { initializeUserModel } from 'models/user';

export const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState: initializeUserModel(),
  reducers: {
    setLoginUser: (state, action) => {
      // ログインユーザーのモデル
      state = { ...action.payload };
    },
  },
});

export const { setLoginUser } = loginUserSlice.actions;
export default loginUserSlice.reducer;

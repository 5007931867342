import { SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { resetPassword, getUser } from 'api/user';
import { Path, StatusCode, UserType } from 'enum';
import useCommon from 'features/useCommon';
import PasswordModel, { initializePasswordModel } from 'models/password';
import UserModel from 'models/user';
import { setLoginUser } from 'slices/loginUserSlice';
import { setToken } from 'slices/tokenSlice';
import * as yup from 'yup';

/**
 * Passwordのカスタムフック
 * @returns
 */
const usePassword = () => {
  // 共通フック
  const {
    t,
    navigate,
    location,
    dispatch,
    showMessage,
    enqueueSnackbar,
    subsequentApiProcess,
    loading,
  } = useCommon();

  // URLパラメータの取得
  const search = location.search;
  const query = new URLSearchParams(search);
  const temp = query.get('temp') ?? '';

  // バリデーションルール
  const schema = yup.object({
    password: yup
      .string()
      .required(t('password.message.パスワードを入力してください。'))
      .min(8, t('password.message.8文字以上36文字以下で入力してください。'))
      .max(36, t('password.message.8文字以上36文字以下で入力してください。')),
    comfirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], t(`password.message.パスワードが一致していません。`)),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordModel>({
    resolver: yupResolver(schema),
    defaultValues: initializePasswordModel(temp),
  });

  /**
   * パスワード変更ボタンクリック
   * @param data PasswordModel
   */
  const onSubmit: SubmitHandler<PasswordModel> = async (data) => {
    console.log(`onSubmit start data: ${data}`);

    // api でデータの取得
    loading(true);
    const result = await resetPassword(data);
    switch (result.status) {
      case StatusCode.Accepted:
        await showMessage(
          t('password.message.有効期限が切れているため、再度、メールを発行してください。')
        );
        return;
    }

    subsequentApiProcess(result.status, async () => {
      const token = result.data?.token as string;
      // トークンの格納
      dispatch(setToken(token));
      sessionStorage.setItem('token', token);
      enqueueSnackbar(t('password.message.パスワードを変更しました。'), { variant: 'success' });
      // 次画面に遷移する
      await showNextScreen();
    });
    loading(false);
    console.log(`onSubmit end`);
  };

  /**
   * 次画面に遷移する
   */
  const showNextScreen = async () => {
    const result = await getUser();
    subsequentApiProcess(result.status, () => {
      const userModel = result.data as UserModel;
      // reduxでデータ保持
      dispatch(setLoginUser(userModel));
      if (userModel.userType === UserType.farmer) {
        // 農家のログイン後のURL
        navigate(Path.cropsList);
      } else {
        // 購入者のログイン後のURL
        navigate(Path.shop);
      }
    });
  };

  return {
    t,
    onSubmit,
    register,
    handleSubmit,
    errors,
  };
};

export default usePassword;

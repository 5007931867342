import React, { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { getCrops, removeCrops, saveCrops } from 'api/crops';
import { getCultivationList } from 'api/cultivation';
import { getSalesList } from 'api/sales';
import { DialogType } from 'enum';
import { Path } from 'enum';
import useCommon from 'features/useCommon';
import CropsModel, { initializeCropsModel } from 'models/crops';
import CultivationModel from 'models/cultivation';
import SalesModel from 'models/sales';
import * as yup from 'yup';

/**
 * Cropsのカスタムフック
 * @returns
 */
const useCrops = () => {
  // imageBase64
  const [avatar, setAvatar] = useState<string | undefined>(undefined);
  // 共通のカスタムフック
  const { t, navigate, location, showMessage, subsequentApiProcess, enqueueSnackbar, loading } =
    useCommon();
  // 新規フラグ
  const [isNew, setIsNew] = useState(true);
  // バリデーションルール
  const schema = yup.object().shape({
    cropsName: yup
      .string()
      .required(t('crops.message.名前を入力してください。'))
      .max(50, t('crops.message.50文字以下で入力してください。')),
    variety: yup
      .string()
      .required(t('crops.message.品種を入力してください。'))
      .max(50, t('crops.message.50文字以下で入力してください。')),
    location: yup.string().max(50, t('crops.message.50文字以下で入力してください。')),
    area: yup.string().max(7, t('crops.message.7文字以下で入力してください。')),
    salsDate: yup.date(),
    memo: yup.string().max(200, t('crops.message.200文字以下で入力してください。')),
  });

  // 作物ID
  const cropsId = (location.state.cropsId as number) ?? 0;

  // useFormの設定
  const {
    control,
    register,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CropsModel>({
    resolver: yupResolver(schema),
    defaultValues: async () => fetchCrops(),
  });

  /**
   * 作物情報を取得する
   */
  const fetchCrops = async (): Promise<CropsModel> => {
    loading(true);
    let cropsModel = initializeCropsModel();
    if (cropsId !== 0) {
      const result = await getCrops(cropsId);
      subsequentApiProcess(result.status, () => {
        cropsModel = result.data as CropsModel;
        if (cropsModel.cropsImage) {
          setAvatar(`${cropsModel.cropsImage}`);
        }
        setIsNew(false);
      });
    }
    loading(false);
    return cropsModel;
  };

  /**
   * アバターのチェンジイベント
   * @param e event
   */
  const imageChange = useCallback(async (e: { file: File; file64: string }) => {
    console.debug(`imageChange start ${e}`);
    setValue('cropsImage', e.file64);
    console.debug(`imageChange end`);
  }, []);

  /**
   * 野菜一覧をクリック
   * @param e event
   */
  const onYasaiClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.debug(`onYasaiClick start e: ${e}`);
    reset();
    // 野菜一覧へ遷移する
    navigate(Path.cropsList);
    console.debug(`onYasaiClick end`);
  };

  /**
   * 保存をクリック
   * @param e event
   */
  const onSaveClick: SubmitHandler<CropsModel> = async (data) => {
    console.log(`onSaveClick start e: ${JSON.stringify(data)}`);
    loading(true);
    await save(data);
    loading(false);
    console.log(`onSaveClick end`);
  };

  /**
   * 削除をクリック
   * @param e event
   */
  const onDeleteClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> => {
    console.debug(`onDeleteClick start ${e}`);
    if (await showMessage(t(`common.message.削除してもよろしいですか？`), DialogType.OkCancel)) {
      loading(true);
      if (await canRemove()) {
        await remove();
      }
      loading(false);
    }
    console.debug(`onDeleteClick end`);
  };

  /**
   * 新規登録処理
   * @param data cropsModel
   */
  const save = async (data: CropsModel): Promise<void> => {
    const result = await saveCrops(data);
    subsequentApiProcess(result.status, () => {
      // 更新データの格納
      if (data.cropsId === 0) {
        setValue('userId', result.data?.userId as number);
        setValue('cropsId', result.data?.cropsId as number);
      }
      setValue('updateDateTime', result.data?.updateDateTime as Date);
      setValue('version', result.data?.version as number);
      // メッセージの格納
      enqueueSnackbar(t(`common.message.保存しました。`), { variant: 'success' });
      setIsNew(false);
    });
  };

  /**
   * 削除検証
   * @returns true: 削除できる、false: 削除できない
   */
  const canRemove = async (): Promise<boolean> => {
    const cropsId = getValues('cropsId');
    const cultivations = (await getCultivationList(cropsId)).data as CultivationModel[];
    const saleses = (await getSalesList(cropsId)).data as SalesModel[];
    if (cultivations.length > 0 || saleses.length > 0) {
      await showMessage(t(`crops.message.栽培、販売情報が登録されているため、削除できません。`));
      return false;
    } else {
      return true;
    }
  };

  /**
   * 削除処理
   */
  const remove = async (): Promise<void> => {
    const cropsId = getValues('cropsId');
    const version = getValues('version');
    // 削除処理
    const result = await removeCrops(cropsId, version);
    subsequentApiProcess(result.status, () => {
      // メッセージの格納
      enqueueSnackbar(t(`common.message.削除しました。`), { variant: 'success' });
      navigate(Path.cropsList);
    });
  };

  return {
    t,
    avatar,
    control,
    errors,
    register,
    handleSubmit,
    isNew,
    imageChange,
    onYasaiClick,
    onSaveClick,
    onDeleteClick,
  };
};

export default useCrops;

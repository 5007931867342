import * as React from 'react';

import { Logout } from '@mui/icons-material';
import { AppBar, Box, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { Path } from 'enum';

import useMenuBar from './useMenuBar';

/**
 * メニューバーの引数
 */
interface MenuBarProps {
  /**
   * タイトル
   */
  title?: string;
  /**
   * タイトルカラー
   */
  titleColor?: string;
  /**
   * 色
   */
  color?: string;
  /**
   * ログアウトボタンの表示／非表示
   */
  isVisibleLogout?: boolean;
  /**
   * 内部要素
   */
  children?: React.ReactNode;
}

/**
 * メニューバー
 * @param props MenuBarProps
 * @returns
 */
const MenuBar = ({
  title = '',
  titleColor = '',
  color = 'green',
  isVisibleLogout = true,
  children = undefined,
}: MenuBarProps) => {
  // カスタムフックを取得
  const { onSignOutCilck, location } = useMenuBar();

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" style={{ background: color }}>
          <Toolbar>
            <Typography color={titleColor} variant="h4">
              {title}
            </Typography>
            <Grid sx={{ ml: 1 }}>{children}</Grid>
            <Typography sx={{ flex: 1 }} />
            {location.pathname !== Path.farmerSignin && isVisibleLogout && (
              <IconButton color="inherit" onClick={onSignOutCilck}>
                <Logout />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        {/** Toolbarの高さを確保する */}
        <Toolbar sx={{ mb: 2 }} />
      </Box>
    </React.Fragment>
  );
};

export default MenuBar;

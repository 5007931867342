import EmailModel from 'models/email';
import UserModel from 'models/user';
import { Result, apiAccess } from 'utils/apiUtils';

/**
 * 保存APIを起動する
 * @param model 購入モデル
 * @returns PurchaseModel
 */
export const saveTempUser = async (model: UserModel): Promise<Result<UserModel>> => {
  return await apiAccess('POST', 'tempuser', model);
};

/**
 * Emailパスワードを起動する
 * @param model Email モデル
 * @returns
 */
export const resetPassword = async (model: EmailModel): Promise<Result<UserModel>> => {
  return await apiAccess('POST', 'tempuser/password', model);
};

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CropsModel from 'models/crops';
import CultivationModel from 'models/cultivation';
import SalesModel from 'models/sales';

/**
 * useCropsItemのカスタムフック
 * @returns CropsItemで使用するカスタムフック
 */
const useCropsItem = (props: {
  cropsModel: CropsModel;
  cultivationModelList: CultivationModel[];
  salesModelList: SalesModel[];
}) => {
  // 多言語化対応
  const { t } = useTranslation();

  /**
   * 販売中 or 販売予定を出力する
   * @param date 日付
   */
  const getSalesString = useMemo((): string => {
    if (props.salesModelList.length > 0) {
      return t(`cropsItem.label.【販売中】`);
    } else {
      return t(`cropsItem.label.【販売予定】`);
    }
  }, []);

  return {
    t,
    getSalesString,
  };
};

export default useCropsItem;

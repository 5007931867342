import React from 'react';

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import PurchaseModel from 'models/purchase';
import SalesModel from 'models/sales';
import UserModel from 'models/user';
import CommonUtils from 'utils/commonUtils';

import PotentialBuyerItem from './potentialBuyerItem';

/**
 * 購入希望ページ
 * @returns
 */
const SalesItem = (props: {
  salesModel: SalesModel;
  purchaseModelList?: PurchaseModel[];
  getUserModel: (userId: number) => UserModel | undefined;
  onPurchaseClick: (purchaseId: number) => void;
  onDeclineClick: (purchaseId: number) => void;
}) => {
  return (
    <Paper>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              sx={{
                bgcolor: 'green',
                width: 60,
                height: 60,
                my: 2,
                mr: 1,
              }}
              alt={props.salesModel.salesName}
              src={CommonUtils.base64ImageSrc(props.salesModel.salesImage)}
            />
          </ListItemAvatar>
          <ListItemText
            primary={props.salesModel.salesName}
            primaryTypographyProps={{ variant: 'h5' }}
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {props.salesModel.salesName}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </List>
      {props.purchaseModelList?.map((purchaseModel) => {
        return (
          <PotentialBuyerItem
            key={purchaseModel.purchaseId}
            salesModel={props.salesModel}
            userModel={props.getUserModel(purchaseModel.userId)}
            purchaseModel={purchaseModel}
            onPurchaseClick={props.onPurchaseClick}
            onDeclineClick={props.onDeclineClick}
          ></PotentialBuyerItem>
        );
      })}
    </Paper>
  );
};

export default SalesItem;

import React from 'react';
import { Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import { faCarrot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalShipping } from '@mui/icons-material';
import {
  Badge,
  BottomNavigationAction,
  Box,
  Chip,
  Container,
  FormControlLabel,
  InputAdornment,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import AvatarFileUpload from 'components/avatarFileUpload';
import BottomAppBar from 'components/bottomAppBar';
import CalendarTextField from 'components/calendarTextField';
import MenuAppBar from 'components/menuBar';
import NavigateMotion from 'components/navigateMotion';
import { Unit } from 'data';
import { FaBox, FaSave, FaWeightHanging } from 'react-icons/fa';
import { GiTomato } from 'react-icons/gi';
import { MdDelete } from 'react-icons/md';
import { RiFootprintFill, RiScales2Fill } from 'react-icons/ri';

import useSales from './useSales';

/**
 * 販売情報ページ
 * @returns
 */
const Sales: React.FC = () => {
  const {
    t,
    avatar,
    control,
    errors,
    register,
    handleSubmit,
    isNew,
    imageChange,
    unitChipClick,
    onYasaiClick,
    onSaveClick,
    onDeleteClick,
  } = useSales();

  return (
    <React.Fragment>
      <MenuAppBar title={t('sales.title')}>
        {/* TODO: 足跡機能の未実装 */}
        <Badge style={{ display: 'none' }} badgeContent={2} color="primary">
          <RiFootprintFill size={'28px'} />
        </Badge>
      </MenuAppBar>
      <Container maxWidth={'sm'} component="form" onSubmit={handleSubmit(onSaveClick)}>
        <NavigateMotion>
          {/** ----------
            * 写真
            ---------- */}
          <AvatarFileUpload base64Image={avatar} onChange={imageChange} />

          <Stack spacing={5}>
            {/** ----------
             * 名前
             ---------- */}
            <TextField
              {...register('salesName')}
              error={'salesName' in errors}
              helperText={errors.salesName?.message}
              label={t('sales.label.名前')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <GiTomato />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * 販売日
             ---------- */}
            <CalendarTextField
              name="salesDate"
              control={control}
              label={t('sales.label.販売日')}
              error={'salesDate' in errors}
              helperText={errors.salesDate?.message}
            />
            {/** ----------
             * 販売金額
             ---------- */}
            <Controller
              name="amount"
              control={control}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <NumericFormat
                  type="tel"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onValueChange={(v) => onChange(v.floatValue)}
                  allowNegative={false}
                  thousandSeparator=","
                  customInput={TextField}
                  error={'amount' in errors}
                  helperText={errors.amount?.message}
                  label={t('sales.label.販売金額')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">¥</InputAdornment>,
                  }}
                />
              )}
            />
            {/** ----------
             * 単位
             ---------- */}
            <TextField
              {...register('unit')}
              error={'unit' in errors}
              helperText={errors.unit?.message}
              label={t('sales.label.単位')}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaWeightHanging />
                  </InputAdornment>
                ),
              }}
            />
            <Box style={{ margin: '5px' }}>
              {Unit.map((name, index) => {
                return (
                  <React.Fragment key={index}>
                    <Chip
                      sx={{ mr: 2, mb: 1 }}
                      label={name}
                      variant="outlined"
                      onClick={(_) => unitChipClick(name)}
                    />
                  </React.Fragment>
                );
              })}
            </Box>
            {/** ----------
             * 入り数
             ---------- */}
            <Controller
              name="quantity"
              control={control}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <NumericFormat
                  type="tel"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onValueChange={(v) => onChange(v.floatValue)}
                  allowNegative={false}
                  thousandSeparator=","
                  customInput={TextField}
                  error={'quantity' in errors}
                  helperText={errors.quantity?.message}
                  label={t('sales.label.入り数')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <RiScales2Fill />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/** ----------
             * 注文可能数
             ---------- */}
            <Controller
              name="stock"
              control={control}
              render={({ field: { name, value, onBlur, onChange } }) => (
                <NumericFormat
                  type="tel"
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onValueChange={(v) => onChange(v.floatValue)}
                  allowNegative={false}
                  thousandSeparator=","
                  customInput={TextField}
                  error={'stock' in errors}
                  helperText={errors.stock?.message}
                  label={t('sales.label.注文可能数')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FaBox />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            {/** ----------
             * 販売方法
             ---------- */}
            <TextField
              {...register('memo')}
              error={'memo' in errors}
              helperText={errors.memo?.message}
              label={t('sales.label.販売方法')}
              multiline
              rows={5}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalShipping />
                  </InputAdornment>
                ),
              }}
            />
            {/** ----------
             * 公開する
             ---------- */}
            <FormControlLabel
              label={t('sales.label.公開する')}
              control={
                <Controller
                  name="isPublish"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      checked={value}
                    />
                  )}
                />
              }
            />
          </Stack>
        </NavigateMotion>
        <BottomAppBar>
          <BottomNavigationAction
            label={t('menu.やさい一覧')}
            icon={<FontAwesomeIcon icon={faCarrot} />}
            onClick={onYasaiClick}
          />
          <BottomNavigationAction label={t('menu.保存')} icon={<FaSave />} type="submit" />
          {!isNew && (
            <BottomNavigationAction
              label={t('menu.削除')}
              icon={<MdDelete />}
              type="button"
              onClick={onDeleteClick}
            />
          )}
        </BottomAppBar>
      </Container>
    </React.Fragment>
  );
};

export default Sales;

import React from 'react';

import SwiperCore, { Navigation, Pagination, Autoplay, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

/**
 * スライドを表示するコンポーネント
 * @returns スライド
 */
const Slide = (props: { imageList: { key: number; value: string }[] }) => {
  return (
    <Swiper
      style={{ marginTop: 2 }}
      slidesPerView={1}
      spaceBetween={0}
      loop={true}
      loopAdditionalSlides={1}
      speed={1500}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      centeredSlides={true}
      grabCursor={true}
      watchSlidesProgress={true}
      breakpoints={{
        300: {
          slidesPerView: 4,
        },
        500: {
          slidesPerView: 6,
        },
        1025: {
          slidesPerView: 8,
          spaceBetween: 0,
        },
      }}
    >
      {props.imageList.map((image) => {
        return (
          <SwiperSlide key={`${image.key}`}>
            <img width="100%" src={image.value} alt={`${image.key}`} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Slide;

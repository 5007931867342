import * as React from 'react';

import { motion } from 'framer-motion';

/**
 * 画面遷移のアニメーションの引き数
 * ReactNode
 */
interface NavigateProps {
  /**
   * ReactNode
   */
  children: React.ReactNode;
}

/**
 * 画面遷移のアニメーション
 */
const NavigateMotion = (props: NavigateProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }} // 初期状態
      animate={{ opacity: 1, y: 0 }} // マウント時
      exit={{ opacity: 0, y: 10 }} // アンマウント時
      transition={{
        duration: 2.5,
      }}
    >
      {props.children}
    </motion.div>
  );
};
export default NavigateMotion;

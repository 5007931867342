import React, { useState, useEffect } from 'react';

import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, IconButton } from '@mui/material';
import imageCompression from 'browser-image-compression';
import CommonUtils from 'utils/commonUtils';

import Loading from './loading';

/**
 * FileUploadImage
 * @param base64Image 画像文字列
 * @returns FileUploadImageタグ
 */
const FileUploadImage = ({
  image64 = undefined,
  variant = 'circular',
}: {
  image64?: string;
  variant?: 'circular' | 'rounded' | 'square';
}) => {
  console.debug(`FileUploadImage start`);

  const width = 150;
  const height = 150;

  // imageデータなし
  if (!image64) {
    console.debug(`FileUploadImage no data end`);
    return (
      <Avatar
        sx={{ m: 1, width: width, height: height, bgcolor: 'secondary.main' }}
        variant={variant}
      >
        <FontAwesomeIcon size={'2x'} icon={faCamera} />
      </Avatar>
    );
  }
  console.debug(`FileUploadImage !chrome end`);
  return (
    <Avatar
      variant={variant}
      src={CommonUtils.base64ImageSrc(image64)}
      sx={{ m: 1, width: width, height: height }}
      alt=""
    ></Avatar>
  );
};

/**
 * Avatarのファイルアップロード部品
 * @param avatar base64文字列
 * @param variant 形
 * @param onChange 画像変更イベント
 * @returns fileUploadAvatar
 */
const AvatarFileUpload = ({
  base64Image = undefined,
  variant = 'circular',
  onChange,
}: {
  base64Image?: string;
  variant?: 'circular' | 'rounded' | 'square';
  onChange: (e: { file: File; file64: string }) => void;
}) => {
  // 表示用
  const [image64, setImage64] = useState<string | undefined>();

  // ローディング
  const [loadingOpen, setLoadingOpen] = useState(false);

  // useEffect
  useEffect(() => {
    setImage64(base64Image);
  }, [base64Image]);

  /**
   * 400Kになるようにファイルを圧縮する
   * @param image 圧縮対象のファイル
   * @returns 圧縮されたファイル
   */
  const compressImage = async (image: File): Promise<File> => {
    const compressFile = await imageCompression(image, {
      maxSizeMB: 0.01,
    });
    return compressFile;
  };

  /**
   * ファイルの変更イベント
   * @param e イベント
   * @returns void
   */
  const imageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    console.debug(`imageChange start ${e}`);

    // ファイル指定なし
    if (e.target.files == null || e.target.files?.length === 0) {
      return;
    }

    const image = e.target.files[0];
    if (image) {
      // 圧縮は遅いので、ローディング
      setLoadingOpen(true);
      const compressedImage = await compressImage(image);
      let imageStr = '';
      if (CommonUtils.isChrome()) {
        imageStr = await CommonUtils.imageToBase64(compressedImage);
      }
      setImage64(imageStr);
      onChange({ file: compressedImage, file64: imageStr });
      setLoadingOpen(false);
    }
    console.debug(`imageChange end`);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 5,
        }}
      >
        <input
          id="file-button"
          accept="image/*"
          style={{ display: 'none' }}
          type="file"
          onChange={imageChange}
        />
        <label htmlFor="file-button">
          <IconButton component="span">
            <FileUploadImage image64={image64} variant={variant}></FileUploadImage>
          </IconButton>
        </label>
      </Box>
      <Loading open={loadingOpen} />
    </>
  );
};

export default AvatarFileUpload;

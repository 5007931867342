import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { signIn, getUser } from 'api/user';
import { Path, StatusCode, UserType } from 'enum';
import useCommon from 'features/useCommon';
import SignInModel from 'models/signIn';
import UserModel from 'models/user';
import { setLoginUser } from 'slices/loginUserSlice';
import { setToken } from 'slices/tokenSlice';
import StorageUtils from 'utils/storageUtils';
import * as yup from 'yup';

/**
 * SignInのカスタムフック
 * @returns
 */
const useSignIn = (props: { userType: UserType }) => {
  // 共通フック
  const { t, navigate, showMessage, enqueueSnackbar, dispatch, loading } = useCommon();
  useEffect(() => {
    StorageUtils.removeToken();
  }, []);

  // バリデーションルール
  const schema = yup.object({
    email: yup
      .string()
      .required(t('signIn.message.メールアドレスを入力してください。'))
      .email(t('signIn.message.メールアドレスを入力してください。')),
    password: yup.string().required(t('signIn.message.パスワードを入力してください。')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInModel>({
    resolver: yupResolver(schema),
  });

  /**
   * SignInボタンクリック
   * @param data SignInput
   */
  const onSubmit: SubmitHandler<SignInModel> = async (data) => {
    console.log(`onSubmit data: ${data}`);

    // ユーザー種別を設定
    data.userType = props.userType;

    // api でデータの取得
    loading(true);
    const result = await signIn(data);
    const token = result.data?.token as string;

    // 画面遷移
    switch (result.status) {
      case StatusCode.Success:
        // トークンの格納
        dispatch(setToken(token));
        StorageUtils.setToken(token);

        // ユーザー情報をステートに設定する
        await setUserModelState();

        // 次画面に遷移する
        navigateNext();

        break;
      case StatusCode.Unauthorized:
        await showMessage(t(`signIn.message.メールアドレスもしくは、パスワードが間違っています。`));
        break;
      default:
        await showMessage(
          t(`common.message.システムエラーが発生しました。管理者に連絡してください。`)
        );
        break;
    }
    loading(false);
  };

  /**
   * 次画面に遷移する
   */
  const navigateNext = () => {
    enqueueSnackbar(t('signIn.message.ログインしました。'), { variant: 'success' });
    if (props.userType === UserType.farmer) {
      navigate(Path.cropsList);
    } else {
      // 遷移元に戻る
      navigate(-1);
    }
  };

  /**
   * 農家情報を取得する
   * @returns Promise
   */
  const setUserModelState = async () => {
    try {
      // 農家情報を取得
      const result = await getUser();
      if (result.status !== StatusCode.Success) {
        console.error(result.error?.message);
        throw result.error;
      }
      const user = result.data as UserModel;
      dispatch(setLoginUser(user));
      StorageUtils.setUser(user);
    } catch {
      await showMessage(
        t(`common.message.システムエラーが発生しました。管理者に連絡してください。`)
      );
    }
  };

  /**
   * 農家作成リンクをクリック
   * @param e event
   */
  const onCreateFarmerClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log(`createFarmerClick start ${e}`);

    if (props.userType === UserType.farmer) {
      navigate(Path.farmerCreate);
    } else {
      navigate(Path.buyerCreate);
    }
    console.log(`createFarmerClick end`);
  };

  /**
   * パスワードを忘れたリンククリック
   * @param e event
   * @returns
   */
  const onForgetPasswordClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log(`forgetPasswordClick start ${e}`);
    if (props.userType === UserType.farmer) {
      navigate(Path.farmerEmail);
    } else {
      navigate(Path.buyerEmail);
    }
    console.log(`forgetPasswordClick end`);
  };

  /**
   * 販売店へ戻るクリックイベント
   * @param e event
   */
  const onShopBackClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log(`onShopBackClick start ${e}`);
    navigate(Path.shop);
  };

  return {
    t,
    register,
    handleSubmit,
    errors,
    onSubmit,
    onCreateFarmerClick,
    onForgetPasswordClick,
    onShopBackClick,
  };
};

export default useSignIn;

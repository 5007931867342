import React from 'react';

import { Search } from '@mui/icons-material';
import { IconButton, InputBase, Paper } from '@mui/material';

import useSearchTextField from './useSearchTextField';

const SearchTextField = (props: {
  placeholder?: string;
  onSearchClick: (e: { searchText: string }) => void;
}) => {
  const { register, handleSubmit, onSearchClick } = useSearchTextField(props);
  return (
    <Paper
      component="form"
      onSubmit={handleSubmit(onSearchClick)}
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        {...register('searchText')}
        placeholder={props.placeholder}
      />

      <IconButton type="submit" sx={{ p: '10px' }}>
        <Search />
      </IconButton>
    </Paper>
  );
};

export default SearchTextField;

import React from 'react';
import { Link } from 'react-router-dom';

import { Edit } from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { Path } from 'enum';
import CultivationModel from 'models/cultivation';
import CommonUtils from 'utils/commonUtils';

/**
 * 栽培ページ
 * @returns
 */
const CultivationItem: React.FC<CultivationModel> = (props) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemAvatar>
        <Avatar
          sx={{
            bgcolor: 'green',
            width: 40,
            height: 40,
            my: 1,
            mr: 1,
          }}
          alt={`${props.workType}`}
          src={CommonUtils.base64ImageSrc(props.cultivationImage)}
        />
      </ListItemAvatar>
      <ListItemText
        primary={CommonUtils.dateFormat(props.workingDate)}
        primaryTypographyProps={{ variant: 'h6' }}
        secondary={
          <React.Fragment>
            <Typography
              sx={{ mr: 1, display: 'inline' }}
              component="span"
              variant="h5"
              color="text.primary"
            >
              {props.workType}
            </Typography>
            <br />
            <Typography
              sx={{ whiteSpace: 'pre-line', display: 'inline' }}
              component="span"
              variant="h6"
              color="text.primary"
            >
              {props.memo}
            </Typography>
          </React.Fragment>
        }
      />
      <IconButton sx={{ width: 30, height: 30 }}>
        <Link
          to={Path.cultivation}
          state={{ cultivationId: props.cultivationId, cropsId: props.cropsId }}
        >
          <Edit />
        </Link>
      </IconButton>
    </ListItem>
  );
};

export default CultivationItem;

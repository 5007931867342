import React from 'react';

import { faCarrot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BottomNavigationAction, Container } from '@mui/material';
import BottomAppBar from 'components/bottomAppBar';
import MenuAppBar from 'components/menuBar';
import NavigateMotion from 'components/navigateMotion';

import SalesItem from './components/salesItem';
import usePotentialBuyerList from './usePotentialBuyerList';

/**
 * 購入希望ページ
 * @returns
 */
const potentialBuyerList = () => {
  const {
    t,
    salesModelList,
    getPurchaseModelList,
    getUserModel,
    onPurchaseClick,
    onDeclineClick,
    onYasaiClick,
  } = usePotentialBuyerList();

  return (
    <React.Fragment>
      <NavigateMotion>
        <MenuAppBar title={t('potentialBuyerList.title')} />
        <Container>
          {salesModelList?.map((salesModel) => {
            return (
              <SalesItem
                key={salesModel.salesId}
                salesModel={salesModel}
                purchaseModelList={getPurchaseModelList(salesModel.salesId)}
                getUserModel={getUserModel}
                onPurchaseClick={onPurchaseClick}
                onDeclineClick={onDeclineClick}
              />
            );
          })}
        </Container>
      </NavigateMotion>

      <BottomAppBar>
        <BottomNavigationAction
          label={t('menu.やさい一覧')}
          icon={<FontAwesomeIcon icon={faCarrot} />}
          onClick={onYasaiClick}
        />
      </BottomAppBar>
    </React.Fragment>
  );
};

export default potentialBuyerList;

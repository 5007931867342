/**
 * 作物モデル
 */
export default interface CropsModel {
  /**
   * 作物キー
   */
  cropsId: number;
  /**
   * 農家キー
   */
  userId: number;
  /**
   * ファイルイメージ
   */
  cropsImageFile?: File;
  /**
   * ファイルイメージ（文字列）
   */
  cropsImage?: string;
  /**
   * 名前
   */
  cropsName: string;
  /**
   * 品種
   */
  variety: string;
  /**
   * 場所
   */
  location: string;
  /**
   * 面積
   */
  area?: string;
  /**
   * 販売予定日
   */
  salesScheduleDate?: Date;
  /**
   * メモ
   */
  memo: string;
  /**
   * 公開する
   */
  isPublish: boolean;
  /**
   * 更新日時
   */
  updateDateTime: Date;
  /**
   * バージョン
   */
  version: number;
}

/**
 * 作物モデルの初期設定値を取得する
 * @returns 作物モデル
 */
export const initializeCropsModel = (): CropsModel => {
  return {
    cropsId: 0,
    userId: 0,
    cropsImageFile: undefined,
    cropsImage: undefined,
    cropsName: '',
    variety: '',
    location: '',
    area: '',
    salesScheduleDate: new Date(),
    memo: '',
    isPublish: true,
    updateDateTime: new Date(),
    version: 0,
  };
};

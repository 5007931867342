import React from 'react';
import { Controller, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField } from '@mui/material';
import {
  MobileDatePicker,
  LocalizationProvider,
  PickersDayProps,
  PickersDay,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';

import useCalendarTextFiled from './useCalendarTextField';

/**
 * カレンダーテキストフィールド
 * @param props 引き数
 * @returns
 */
const CalendarTextField = (props: {
  name: string;
  /* eslint-disable */
  control: Control<any>;
  defaultValue?: Date;
  label: React.ReactNode;
  error: boolean;
  helperText: React.ReactNode;
}) => {
  // 多言語化対応
  const { t } = useTranslation();

  // カスタムフック取得
  const { switchDayColor } = useCalendarTextFiled();

  /**
   * 日付のスタイルを変更する
   * @param date 日付
   * @param _selectedDates 選択日付
   * @param pickersDayProps pickerDay引数
   * @returns PickerDay
   */
  const customPickerDay = (
    date: Date,
    _selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>
  ) => {
    /**
     * 変更後のPickerProps
     */
    const newPickersDayProps = {
      ...pickersDayProps,
      sx: switchDayColor(date),
    };
    return <PickersDay {...newPickersDayProps} />;
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ja}
      dateFormats={{ monthAndYear: t('yyyy年M月') }}
    >
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.defaultValue}
        render={({ field }) => (
          <MobileDatePicker
            {...field}
            label={props.label}
            inputFormat={t('yyyy年M月d日')}
            toolbarFormat={t('M月d日')}
            mask={t('____年__月__日')}
            renderDay={customPickerDay}
            renderInput={(params) => (
              <TextField
                {...params}
                error={props.error}
                helperText={props.helperText}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faCalendarDays} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default CalendarTextField;

import { useTranslation } from 'react-i18next';

import { PurchaseType } from 'enum';
/**
 * PotentialBuyerItemのカスタムフック
 * @returns SelesItemで使用するカスタムフック
 */
const usePotentialBuyerItem = () => {
  // 多言語化対応
  const { t } = useTranslation();
  /**
   * 購入区分から表示する文字を決定する
   * @param purchaseType 購入区分
   */
  const getPurchaseTypeName = (purchaseType: string): string => {
    switch (purchaseType) {
      case PurchaseType.confirm:
        return t('potentialBuyerList.label.確');
      case PurchaseType.decline:
        return t('potentialBuyerList.label.断');
      case PurchaseType.cancel:
        return t('potentialBuyerList.label.取');
      default:
        return t('potentialBuyerList.label.未');
    }
  };
  /**
   * 購入区分から表示する文字を決定する
   * @param purchaseType 購入区分
   * @return 色
   */
  const getPurchaseTypeColor = (purchaseType: string): string => {
    switch (purchaseType) {
      case PurchaseType.confirm:
        return 'blue';
      case PurchaseType.decline:
        return 'red';
      case PurchaseType.cancel:
        return 'gray';
      default:
        return '';
    }
  };

  /**
   * 購入区分からデフォルトで開いているかどうかを判断する
   * @param purchaseType 購入区分
   * @return true: 開く、false: 開かない
   */
  const getDefaultExpanded = (purchaseType: string): boolean => {
    switch (purchaseType) {
      case PurchaseType.confirm:
      case PurchaseType.decline:
      case PurchaseType.cancel:
        return false;
      default:
        return true;
    }
  };

  return {
    t,
    getPurchaseTypeName,
    getPurchaseTypeColor,
    getDefaultExpanded,
  };
};

export default usePotentialBuyerItem;

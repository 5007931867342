import SalesModel from 'models/sales';
import ShopModel from 'models/shop';
import { Result, apiAccess } from 'utils/apiUtils';

/**
 * 作物IDを元に販売情報を取得する
 * @param cropsId 作物Id
 */
export const getSalesList = async (cropsId?: number): Promise<Result<SalesModel[]>> => {
  if (cropsId === undefined) {
    return await apiAccess<SalesModel[]>('GET', `sales/list`);
  } else {
    return await apiAccess<SalesModel[]>('GET', `sales/list/${cropsId}`);
  }
};

/**
 * 販売IDから販売情報を取得する
 * @param salesId 販売ID
 */
export const getSales = async (salesId: number): Promise<Result<SalesModel>> => {
  return await apiAccess<SalesModel>('GET', `sales/${salesId}`);
};

/**
 * 購入希望が存在する販売情報リストを取得する
 * @returns 購入希望が存在する販売情報リスト
 */
export const getPurchaseSalesList = async (): Promise<Result<SalesModel[]>> => {
  return await apiAccess<SalesModel[]>('GET', 'sales/purchasesaleslist');
};

/**
 * 購入希望商品を取得する
 * @returns 購入希望商品リスト
 */
export const getPurchaseHistorySalesList = async (): Promise<Result<SalesModel[]>> => {
  return await apiAccess<SalesModel[]>('GET', 'sales/purchasehistorysaleslist');
};

/**
 * 販売情報リストを取得する
 * @returns 販売情報リスト
 */
export const getShopList = async (userId?: number): Promise<Result<ShopModel[]>> => {
  if (userId === undefined) {
    return await apiAccess<ShopModel[]>('GET', 'sales/shoplist');
  } else {
    return await apiAccess<ShopModel[]>('GET', `sales/shoplist/userid/${userId}`);
  }
};

/**
 * 販売情報リストを取得する
 * @param searchText 検索条件
 * @returns 販売情報リスト
 */
export const getShopListBySalesName = async (searchText: string): Promise<Result<ShopModel[]>> => {
  return await apiAccess<ShopModel[]>('GET', `sales/shoplist/salesname/${searchText}`);
};

/**
 * 販売情報を取得する
 * @returns 販売情報
 */
export const getShopItem = async (salesId: number): Promise<Result<ShopModel>> => {
  return await apiAccess<ShopModel>('GET', `sales/shopitem/salesid/${salesId}`);
};

/**
 * 保存APIを起動する
 * @param model 販売モデル
 */
export const saveSales = async (model: SalesModel): Promise<Result<SalesModel>> => {
  if (model.salesId === 0) {
    return await apiAccess('POST', 'sales', model);
  } else {
    return await apiAccess('PUT', 'sales', model);
  }
};

/**
 * 削除APIを起動する
 * @param salesId 販売ID
 * @param version バージョン
 * @returns なし
 */
export const removeSales = async (salesId: number, version: number) => {
  return await apiAccess('DELETE', `sales/${salesId}/${version}`);
};

import * as React from 'react';

import { AccountCircle, History, Home, Login, Logout } from '@mui/icons-material';
import { AppBar, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { Path } from 'enum';

import useShopMenuBar from './useShopMenuBar';

/**
 * メニューバー
 * @param props ShopMenuBarProps
 * @returns
 */
const ShopMenuBar = (props: { children?: React.ReactNode }) => {
  const {
    t,
    location,
    isSignIn,
    onHistoryClick,
    onHomeClick,
    onSignInCilck,
    onSignOutCilck,
    onUserClick,
  } = useShopMenuBar();
  return (
    <React.Fragment>
      <AppBar sx={{ p: 0 }} position="fixed">
        <Toolbar>
          <IconButton color="inherit" onClick={onHomeClick}>
            <Home />
          </IconButton>
          <Typography sx={{ display: { xs: 'none', md: 'flex' } }} variant="h4">
            {t('shop.title')}
          </Typography>
          <Grid sx={{ ml: 2 }}>{props.children}</Grid>
          <Typography sx={{ flex: 1 }} />
          {isSignIn && (
            <React.Fragment>
              <IconButton color="inherit" onClick={onHistoryClick}>
                <History />
              </IconButton>
              <IconButton color="inherit" onClick={onUserClick}>
                <AccountCircle />
              </IconButton>
              <IconButton color="inherit" onClick={onSignOutCilck}>
                <Logout />
              </IconButton>
            </React.Fragment>
          )}
          {!isSignIn && location.pathname !== Path.buyerSignin && (
            <>
              <IconButton color="inherit" onClick={onSignInCilck}>
                <Login />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ mb: 2 }} />
    </React.Fragment>
  );
};

export default ShopMenuBar;

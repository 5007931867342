import * as React from 'react';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { BottomNavigationAction } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import BottomAppBar from 'components/bottomAppBar';
import { UserType } from 'enum';
import { motion } from 'framer-motion';

import useEmail from './useEmail';

/**
 * パスワードページ
 * @returns
 */
const Email = (props: { userType: UserType }) => {
  const { t, register, handleSubmit, errors, onSigninClick, onSubmit } = useEmail(props);

  return (
    <motion.div
      initial={{ opacity: 0 }} // 初期状態
      animate={{ opacity: 1 }} // マウント時
      exit={{ opacity: 0 }} // アンマウント時
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('email.label.パスワード変更')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              fullWidth
              label={t('email.label.メールアドレス')}
              autoComplete="email"
              {...register('email')}
              error={'email' in errors}
              helperText={errors.email?.message}
              autoFocus
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t('email.label.メールを送信する')}
            </Button>
          </Box>
        </Box>
      </Container>
      <BottomAppBar>
        <BottomNavigationAction
          label={t('menu.ログインへ')}
          icon={<LockOutlinedIcon />}
          onClick={onSigninClick}
        />
      </BottomAppBar>
    </motion.div>
  );
};

export default Email;

import CultivationModel from 'models/cultivation';
import { Result, apiAccess } from 'utils/apiUtils';

/**
 * ログインユーザーの作物情報を取得する
 * @param {number} cropsId 作物Id
 */
export const getCultivationList = async (cropsId?: number): Promise<Result<CultivationModel[]>> => {
  if (cropsId === undefined) {
    return await apiAccess<CultivationModel[]>('GET', `cultivation/list`);
  } else {
    return await apiAccess<CultivationModel[]>('GET', `cultivation/list/${cropsId}`);
  }
};

/**
 * 栽培IDから栽培情報を取得する
 * @param cultivationId 栽培ID
 */
export const getCultivation = async (cultivationId: number): Promise<Result<CultivationModel>> => {
  return await apiAccess<CultivationModel>('GET', `cultivation/${cultivationId}`);
};

/**
 * 保存APIを起動する
 * @param model 栽培モデル
 */
export const saveCultivation = async (
  model: CultivationModel
): Promise<Result<CultivationModel>> => {
  if (model.cultivationId === 0) {
    return await apiAccess('POST', 'cultivation', model);
  } else {
    return await apiAccess('PUT', 'cultivation', model);
  }
};

/**
 * 栽培情報の削除APIを呼び出す
 * @param cultivationId 栽培ID
 * @param version バージョンID
 * @returns
 */
export const removeCultivation = async (cultivationId: number, version: number) => {
  return await apiAccess('DELETE', `cultivation/${cultivationId}/${version}`);
};

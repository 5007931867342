import { useCallback, useEffect, useState } from 'react';

import { getScheduleList } from 'api/crops';
import { getShopList, getShopListBySalesName } from 'api/sales';
// import { DialogType } from 'components/messageDialog';
import { Path } from 'enum';
import useCommon from 'features/useCommon';
import Image1 from 'images/main1.jpg';
import Image2 from 'images/main2.jpg';
import Image3 from 'images/main3.jpg';
import Image4 from 'images/main4.jpg';
import Image5 from 'images/main5.jpg';
import ScheduleModel from 'models/schedule';
import ShopModel from 'models/shop';

/**
 * Shopのカスタムフック
 * @returns
 */
const useShop = () => {
  // 共通フック
  const { t, navigate, loading } = useCommon();
  // 販売リスト
  const [shopModelList, setShopModelList] = useState<ShopModel[]>();
  // 販売予定リスト
  const [scheduleModelList, setScheduleModelList] = useState<ScheduleModel[]>();
  /**
   * ページのヘッダーに表示するイメージ
   */
  const imageList = [
    { key: 1, value: Image1 },
    { key: 2, value: Image2 },
    { key: 3, value: Image3 },
    { key: 4, value: Image4 },
    { key: 5, value: Image5 },
  ];

  // 初期データ取得
  useEffect(() => {
    console.log(`useEffect start []`);
    fetchData();
  }, []);

  /**
   * APIからデータ取得
   */
  const fetchData = useCallback(async (): Promise<void> => {
    loading(true);
    Promise.all([fetchShopModelList(''), fetchScheduleModelList()]);
    loading(false);
  }, []);

  /**
   * 販売情報を取得する
   */
  const fetchShopModelList = useCallback(async (searchText: string): Promise<void> => {
    if (searchText == null || searchText.trim() === '') {
      const result = await getShopList();
      setShopModelList(result.data as ShopModel[]);
    } else {
      const result = await getShopListBySalesName(searchText);
      setShopModelList(result.data as ShopModel[]);
    }
  }, []);

  /**
   * 販売予定情報を取得する
   */
  const fetchScheduleModelList = useCallback(async (): Promise<void> => {
    const result = await getScheduleList();
    setScheduleModelList(result.data as ScheduleModel[]);
  }, []);

  /**
   * カードのクリックイベント
   * @param e イベント
   */
  const onCardClick = useCallback((e: { userId: number; salesId: number }): void => {
    console.log(`onCardClick start ${e}`);
    navigate(`${Path.shopItem}/${e.userId}/${e.salesId}`);
    console.log(`onCardClick end`);
  }, []);

  /**
   * 検索条件クリックイベント
   * @param e イベント
   */
  const onSearchClick = useCallback(async (e: { searchText: string }): Promise<void> => {
    console.log(`onSearchClick start ${JSON.stringify(e)}`);
    await getShopModelListBySearchText(e.searchText);
  }, []);

  /**
   * 農家のログインページへ移動
   * @param e event
   */
  const onFarmerSigninClick = useCallback(async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log(`onFarmerSigninClick ${e}`);
    navigate(`${Path.farmerSignin}`);
  }, []);

  /**
   * 検索条件から販売情報を取得する
   * @param searchText 検索条件
   */
  const getShopModelListBySearchText = useCallback(async (searchText: string) => {
    console.log(`getSsalesListBySearchText start`);
    await fetchShopModelList(searchText.trim());
  }, []);

  return {
    t,
    imageList,
    shopModelList,
    scheduleModelList,
    onCardClick,
    onSearchClick,
    onFarmerSigninClick,
  };
};

export default useShop;

/**
 * Passwordインターフェース
 */
export default interface PasswordModel {
  /**
   * password
   */
  password: string;
  /**
   * comfirmPassword
   */
  comfirmPassword: string;
  /**
   * 添付
   */
  temp: string;
}

/**
 * パスワードモデルの初期化
 * @param temp 添付コード
 * @returns PasswordModel
 */
export const initializePasswordModel = (temp: string): PasswordModel => {
  return {
    password: '',
    comfirmPassword: '',
    temp: temp,
  };
};

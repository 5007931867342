import { useEffect, useState } from 'react';

import { DialogType, Path } from 'enum';
import useCommon from 'features/useCommon';
import CommonUtils from 'utils/commonUtils';
import StorageUtils from 'utils/storageUtils';

/**
 * メニューバー
 * @param props ShopMenuBarProps
 * @returns
 */
const useShopMenuBar = () => {
  const [timer, setTimer] = useState(0);
  const [isSignIn, setIsSignIn] = useState(false);
  const { t, navigate, location, showMessage, enqueueSnackbar } = useCommon();

  // 1分に1回ログイン状態を取得する
  setTimeout(() => {
    console.log(`timer start`);
    setTimer(timer + 1);
    console.log(`timer end`);
  }, 60000);

  // ログイン状態を取得する
  useEffect(() => {
    console.log(`useEffect start`);
    isLoginBuyer();
  }, []);

  // タイマー変更通知を取得する
  useEffect(() => {
    console.log(`useEffect start timer`);
    isLoginBuyer();
  }, [timer]);

  /**
   * SignIn状態を取得する
   */
  const isLoginBuyer = async () => {
    setIsSignIn(await CommonUtils.isLoginBuyer());
  };

  /**
   * 履歴ボタンクリックイベント
   * @param e event
   */
  const onHistoryClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    console.debug(`onHistoryClick start ${e}`);
    navigate(Path.purchaseHistory);
  };

  /**
   * ホームボタンクリックイベント
   * @param e event
   */
  const onHomeClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    console.debug(`onHomeClick start ${e}`);
    navigate(Path.shop);
  };

  /**
   * ユーザークリックイベント
   * @param e event
   */
  const onUserClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    console.debug(`onUserClick start ${e}`);
    navigate(Path.buyerUpdate);
  };

  /**
   * サインインボタンクリックイベント
   * @param e event
   */
  const onSignInCilck = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    console.debug(`onSigninCilck start ${e}`);
    navigate(Path.buyerSignin);
  };

  /**
   * サインアウトボタンクリックイベント
   * @param e event
   */
  const onSignOutCilck = async (e: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> => {
    console.debug(`onSignOutCilck start ${e}`);
    if (await showMessage(t(`shopMenuBar.message.ログアウトしますか？`), DialogType.OkCancel)) {
      StorageUtils.removeAll();
      setIsSignIn(false);
      if ([Path.buyerUpdate, Path.purchaseHistory].some((x) => x === location.pathname)) {
        navigate(Path.shop);
      }
      enqueueSnackbar(t('shopMenuBar.message.ログアウトしました。'), { variant: 'success' });
    }
  };
  return {
    t,
    location,
    isSignIn,
    onHistoryClick,
    onHomeClick,
    onSignInCilck,
    onSignOutCilck,
    onUserClick,
  };
};

export default useShopMenuBar;

import * as React from 'react';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';

import useTemporaryRegistration from './useTemporaryRegistration';

/**
 * 仮登録から本登録するページ
 * @returns
 */
const TemporaryRegistration = () => {
  const { t, onRegistClick } = useTemporaryRegistration();

  return (
    <motion.div
      initial={{ opacity: 0 }} // 初期状態
      animate={{ opacity: 1 }} // マウント時
      exit={{ opacity: 0 }} // アンマウント時
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('temporaryRegistration.label.ボタンをクリックしてください。')}
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <Button
              type="button"
              onClick={onRegistClick}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('temporaryRegistration.label.登録する')}
            </Button>
          </Box>
        </Box>
      </Container>
    </motion.div>
  );
};

export default TemporaryRegistration;
